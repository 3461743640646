import { formatDate, fmtDate } from "@/helpers/date";

// статусы для КИ
export const states = {
  draft: "Создан",
  submitted: "На рассмотрении",
  confirmed: "Подтвержден",
  assigned: "Назначен",
  settled: "Урегулирован",
  dismissed: "Отсутствует",
  archived: "Архивный",
};

// переходы для КИ
export const transitions = {
  to_state_draft: "Создан",
  to_state_submitted: "На рассмотрении",
  to_state_confirmed: "Подтвержден",
  to_state_settled: "Урегулирован",
  to_state_dismissed: "Отсутствует",
  to_state_archived: "Архивный",
};

// статусы для КП
export const statesCp = {
  draft: "Создана",
  submitted: "Редактирование завершено",
  assigned: "Назначена",
  checked: "КП проведена",
  prolonged: "КП продлена",
  prochecked: "Ответственный завершил назначение",
  completed: "Завершена",
  archived: "Архивная",
};

// статусы для задач
export const statesTasks = {
  draft: "Создана",
  assigned: "Назначена",
  prolonged: "Продлена",
  completed: "Завершена",
};

// статусы для горячей линии
export const statesHotline = {
  draft: "Черновик",
  submitted: "Создано",
  redirected: "На рассмотрении (ЗГД)",
  delegated: "На рассмотрении (РП)",
  assigned: "На рассмотрении",
  checked: "На рассмотрении (УК)",
  prolonged: "Продлено",
  prochecked: "Продлено (УК)",
  completed: "Рассмотрено",
};

// статусы для Декларации
export const statesDeclarations = {
  new: "Черновик",
  draft: "Создана",
  assigned: "На рассмотрении",
  submitted: "На рассмотрении",
  autochecked: "На рассмотрении",
  checked: "На рассмотрении",
  archived: "В архиве",
  completed: "Рассмотрена",
  commission: "На рассмотрении",
  decision: "На рассмотрении",
};

// статусы для деловых партнеров
export const statesPartners = {
  draft: "Создан", // Черновик
  submitted: "На согласовании (УК и ОБ)", // Начат процесс проверки ДП
  halfway: "На согласовании (УК и ОБ завершили)", // Руководители УК и ОБ завершили проверки (автоматический переход)
  forward: "На согласовании (ФО и ЮО)", // ДП прошел проверку УК и ОБ, начат процесс проверки ФО и ЮО
  pending: "На согласовании (ЮО и ФО завершили)", // Руководители ЮО и ФО и ОБ завершили проверки, нужно завершить процесс (автоматический переход)
  approved: "Согласован", // ДП согласован по итогам проверок (отдельные процессы)
  declined: "Не согласован", // ДП не согласован по итогам проверок (отдельные процессы)
};

// статусы проверок ДП в рабочей области
export const statesPartnerChecks = {
  new: "Новая",
  assigned: "Назначен ответственный за проверку",
  checked: "Ответственный исполнитель представил результаты проверки",
  completed: "Проверка завершена",
};

// переходы для Задач
export const transitionsTask = {
  to_state_draft: "Сохранена",
  to_state_assigned: "Назначена",
  to_state_completed: "Завершена",
};

// переходы для КП
export const transitionsCp = {
  to_state_draft: "Сохранена",
  to_state_assigned: "Назначена",
  to_state_prolonged: "Продлена",
  to_state_completed: "Завершена",
};

// статусы для матриц рисков
export const statesRiskMatrix = {
  draft: "Создана", // Черновик
  submitted: "Отправлена руководителю УК", // Отправлена руководителю УК
  approved: "Одобрена руководителем УК", // Одобрена руководителем УК, отправлена на проверку другим руководителям
  confirmed: "Согласована", // Согласована
  declined: "Не согласована", // Не согласована
};

// статусы для проверок матрицы рисков (согласование)
export const statesCheckMatrix = {
  new: "Новая",
  confirmed: "Согласована", // (утверждена)
  declined: "Не согласована",
};

// статусы рисков
export const statesRisk = {
  draft: "Создан",
  published: "Опубликован",
};

// статусы перевода сотрудника
export const workerToStates = {
  toResigned: "resigned", // уволить
  toWorker: "worker", // принять в работники
};

// статусы пользователей и работников
export const stateWorker = {
  candidate: "Кандидат",
  worker: "Работающий",
  resigned: "Уволен",
  superadmin: "Суперадмин",
  admin: "Администратор",
};

// статусы для программ обучения
export const statesEducProg = {
  draft: "Черновик",
  submitted: "На рассмотрении",
  published: "Опубликована",
  closed: "Снята с публикации",
  archived: "Переведено в архив",
};

// статусы прохождения обучения в ЛК
export const statesEducUser4Profile = {
  new: "Не начато",
  started: "Начато",
  finished: "Пройдено",
  failed: "Не пройдено",
  retake: "Не пройдено, доступна пересдача",
  review: "На проверке",
};
// статусы прохождения обучения
export const statesEducUser = {
  new: "Не начато",
  started: "Начато",
  finished: "Успешно завершено",
  failed: "Провалено",
};

// статусы внутренних проверок
export const statesInnerCheck = {
  draft: "Создана",
  assigned: "Назначена",
  checked: "Проведена",
  completed: "Завершена",
};

// статусы документов базы знаний
export const statesKnowledge = {
  draft: "Черновик",
  submitted: "Создан",
  published: "Опубликован",
  closed: "Снят с публикации",
  archived: "В архиве",
};
export const statesKnowledge4filter = {
  draft: "Черновик",
  submitted: "Создан",
  published: "Опубликован",
  closed: "Снят с публикации",
};

// статусы документов базы знаний в ЛК
export const statesKnowledgeUser = {
  new: "Назначено",
  finished: "Ознакомление завершено",
};

// статусы прохождения теста - личный кабинет
export const statesTestUser = {
  started: "Начато",
  submitted: "Отправлено на проверку",
  checked: "Проверено",
  closed: "Закрыто",
};

// функция отображения статуса обучения
// задача #381
export function statesEducUserText(obj) {
  // если после очередной попытки статус = "Пройден", после этого, даже если поступят повторные попытки, статус не меняется
  // результат попытки положительный, но попытка не последняя - статус "Пройден"
  if (obj.is_success) return statesEducUser4Profile.finished;
  // попыток не было и срок на обучение истек - статус "Не пройден"
  // попытки были, но на момент истечения срока обучения ни одного положительного результата - статус "Не пройден"
  else if (obj.deadline < formatDate(new Date(), fmtDate))
    return statesEducUser4Profile.failed;
  // если в тестировании имеется вопрос, требующий ручной проверки, статус "На проверке", а после проверки в соответствии с ранее перечисленными требованиями.
  else if (
    obj.test_detail &&
    Array.isArray(obj.test_detail) &&
    obj.test_detail.some(
      (x) => statesTestUser[x.state] === statesTestUser.submitted
    )
  )
    return statesEducUser4Profile.review;
  // результат попытки отрицательный, но попытка не последняя - статус "Не пройден, доступна пересдача"
  else if (
    obj.test_detail &&
    Array.isArray(obj.test_detail) &&
    obj.test_detail.length < Number(obj.course_max_attempts)
  )
    return statesEducUser4Profile.retake;
  // результат попытки отрицательный, и попытка последняя - статус "Не пройден"
  else if (
    obj.test_detail &&
    Array.isArray(obj.test_detail) &&
    obj.test_detail.length >= Number(obj.course_max_attempts)
  )
    return statesEducUser4Profile.failed;
  // веренем статус объекта
  else return statesEducUser4Profile[obj.state];
}
