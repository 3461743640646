import { mapGetters, mapActions } from "vuex";
import { formatDate, fmtDateMonthTime, fmtDateShort } from "@/helpers/date";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
import AdminDepartAdd from "./AdminDepartAdd.vue";
import { limitBound, formatName, fmtNameShort } from "@/helpers/text.js";
export default {
  name: "AdminDepart",
  data: () => ({
    showModalRemove: false,
    currentItem: {},
    currentPage: 1,
    gotoPage: 1,
    perPage: 10,
    tableFields: [{
      key: "index",
      label: "№ п/п"
    }, {
      key: "id",
      label: "UID"
    }, {
      key: "department_name",
      label: "Наименование",
      class: "depart-name-list"
    }, {
      key: "senior_detail",
      label: "Руководитель",
      formatter: value => formatName(value, fmtNameShort)
    }, {
      key: "created_at",
      label: "Дата создания",
      formatter: value => formatDate(value, fmtDateMonthTime),
      tdAttr: value => ({
        "data-created": formatDate(value, fmtDateShort)
      }),
      class: "text-nowrap depart-date-list"
    }, {
      key: "btnChange",
      label: "",
      class: "p-0 text-center"
    }, {
      key: "btnRemove",
      label: "",
      class: "p-0 text-center"
    }],
    breadcrumbs: [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }],
    settingsAdd: {
      title: "Создать подразделение",
      btnTitle: "Создать",
      buttonIcon: false,
      iconType: null
    },
    settingsChange: {
      title: "Редактировать подразделение",
      btnTitle: null,
      buttonIcon: true,
      iconType: "pencil-square"
    }
  }),
  components: {
    BreadCrumbToHome,
    theNotification,
    AdminDepartAdd
  },
  created() {
    this.updateAdminDepart({
      page: 1
    });
  },
  methods: {
    ...mapActions("administration", ["updateAdminDepart", "createAdminDepart", "removeAdminDepart", "changeAdminDepart"]),
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.adminDepartCount / this.perPage, this.currentPage);
      this.updateAdminDepart({
        page: this.currentPage
      });
    },
    async clickCreateDepart(data) {
      await this.createAdminDepart(data);
      this.currentPage = 1;
      await this.updateAdminDepart({
        page: 1
      });
    },
    async clickRemove(item) {
      this.currentItem = item;
      this.showModalRemove = true;
    },
    async doRemove() {
      await this.removeAdminDepart(this.currentItem);
      this.currentPage = 1;
      await this.updateAdminDepart({
        page: 1
      });
    },
    async clickChange(data) {
      await this.changeAdminDepart(data);
      await this.updateAdminDepart({
        page: this.currentPage
      });
    }
  },
  computed: {
    ...mapGetters("administration", ["adminDepart", "adminDepartLoading", "adminDepartCount"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateAdminDepart({
          page: value
        });
      }
    }
  }
};