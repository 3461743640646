import "core-js/modules/es.array.push.js";
import StringsWithX from "@/components/Helpers/StringsWithX.vue";
import UserSelector from "@/components/Helpers/UserSelector";
import Calendar from "@/components/Helpers/Calendar.vue";
import { FIO, CONST_NONAME, fmtNameFull } from "@/helpers/text";
import { mapActions, mapGetters } from "vuex";
import { getOrgName } from "@/auth/auth";
import { formatDateObj, fmtDate, dateObjInit, formatDate } from "@/helpers/date";
import { invalidMinMaxDate } from "@/helpers/validate";
export default {
  name: "CourseAssign",
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    StringsWithX,
    Calendar,
    UserSelector
  },
  data: () => ({
    showModal: false,
    fmtNameFull,
    CONST_NONAME,
    allOrg: false,
    // в целом по организации
    orgName: CONST_NONAME,
    depart: null,
    departs: [],
    departsToAssign: [],
    workersToAssign: [],
    dateStart: {
      ...dateObjInit
    },
    dateEnd: {
      ...dateObjInit
    },
    deadline: {
      ...dateObjInit
    },
    showDeadline: true
  }),
  methods: {
    ...mapActions("education", ["assignCourse", "updateStudentsToAss"]),
    ...mapActions(["showNotify"]),
    // нажали кнопку - открываем модальное окно
    async clickAssign() {
      this.orgName = getOrgName();
      this.allOrg = false;
      this.clearDepart();
      this.departsToAssign = [];
      this.workersToAssign = [];
      this.dateStart = {
        ...dateObjInit
      };
      this.dateEnd = {
        ...dateObjInit
      };
      this.deadline = {
        ...dateObjInit
      };
      this.showModal = true;
      this.departs = Object.keys(this.departments).reduce((acc, curr) => {
        acc.push({
          value: this.departments[curr],
          text: curr
        });
        return acc;
      }, []);
    },
    // обработка закрытия модального окна и назначения обучения
    async handlerOk(bvModalEvt) {
      let err = false;
      // проверка работников
      if (!this.allOrg && this.departsToAssign.length === 0 && this.workersToAssign.length === 0) {
        this.showNotify({
          msg: "Список обучающихся пуст",
          title: "Ошибка пользователя",
          variant: "danger"
        });
        err = true;
      }
      // проверка даты дедлайна
      const deadline = formatDateObj(this.deadline, fmtDate, null);
      if (!deadline) {
        this.showNotify({
          msg: "Некорректная дата дедлайна",
          title: "Ошибка пользователя",
          variant: "danger"
        });
        err = true;
      }
      // проверка даты окончания
      const dateEnd = formatDateObj(this.dateEnd, fmtDate, null);
      if (!dateEnd) {
        this.showNotify({
          msg: "Некорректная дата окончания обучения",
          title: "Ошибка пользователя",
          variant: "danger"
        });
        err = true;
      }
      // проверка даты начала
      const dateStart = formatDateObj(this.dateStart, fmtDate, null);
      if (!dateStart) {
        this.showNotify({
          msg: "Некорректная дата начала обучения",
          title: "Ошибка пользователя",
          variant: "danger"
        });
        err = true;
      }
      // ошибка
      if (err) {
        bvModalEvt.preventDefault();
        return;
      }
      // назначаем обучение
      await this.assignCourse({
        course: this.item,
        dateStart,
        dateEnd,
        deadline,
        allOrg: this.allOrg,
        depart: this.departsToAssign,
        workers: this.workersToAssign
      });
      await this.updateStudentsToAss({
        page: 1,
        filters: {
          course_id: this.item.id,
          confirmed: false
        }
      });
      this.showModal = false;
    },
    // очистка закладки Подразделение
    clearDepart() {
      this.depart = null;
    },
    // обработка выбора подразделения
    changeDepart() {
      const choose = this.departs.find(el => el.value === this.depart);
      if (choose) {
        this.departsToAssign.push({
          id: choose.value,
          text: choose.text
        });
        this.clearDepart();
      }
    },
    // обработка выбора работника
    selectedWorker(choose) {
      this.workersToAssign.push({
        id: choose.id,
        text: FIO(choose.name)
      });
    },
    // удаление выбранного подраздления
    removeDeparts(id) {
      const index = this.departsToAssign.findIndex(el => el.id === id);
      if (index >= 0) {
        this.departsToAssign.splice(index, 1);
      }
    },
    // удаление выбранного работника
    removeWorkers(id) {
      const index = this.workersToAssign.findIndex(el => el.id === id);
      if (index >= 0) {
        this.workersToAssign.splice(index, 1);
      }
    },
    changeDateEnd(data) {
      this.showDeadline = false;
      this.dateEnd = data;
      Object.assign(this.deadline, this.dateEnd);
      setTimeout(() => {
        this.showDeadline = true;
      }, 0);
    }
  },
  computed: {
    ...mapGetters("referenceBooks", ["departmentsLoading", "departments"]),
    // список выбора подразделений
    departOptions() {
      return this.departs.filter(dep => !this.departsToAssign.find(el => dep.value === el.id));
    },
    // «Дата начала обучения» не позднее «Даты окончания обучения»
    invalidStartDate() {
      return invalidMinMaxDate(formatDateObj(this.dateStart, fmtDate, null), null, formatDateObj(this.dateEnd, fmtDate, null));
    },
    // «Дата окончания обучения» не ранее даты внесения сведений
    invalidEndDate() {
      return invalidMinMaxDate(formatDateObj(this.dateEnd, fmtDate, null), formatDate(new Date(), fmtDate), null);
    },
    // дедлайн либо = сроку завершения, либо раньше. позже не может быть
    invalidDeadline() {
      return invalidMinMaxDate(formatDateObj(this.deadline, fmtDate, null), formatDateObj(this.dateStart, fmtDate, null), formatDateObj(this.dateEnd, fmtDate, null));
    },
    okDisabled() {
      return Boolean(this.invalidStartDate || this.invalidEndDate || this.invalidDeadline);
    }
  }
};