export function typeText(type) {
  switch (type) {
    case "bp_only":
      return "Деловой партнер";
    case "coid_only":
      return "Декларация";
    default:
      return "Личный листок";
  }
}

export function typeBreadcrumbs(type) {
  switch (type) {
    case "bp_only":
      return [
        {
          text: "Проверка деловых партнеров",
          to: { name: "Partners" },
        },
      ];
    case "coid_only":
      return [
        {
          text: "Декларации",
          to: { name: "Declarations" },
        },
      ];
    default:
      return [
        { text: "Администрирование", to: { name: "AdminArea" } },
        { text: "Проверки личных листков", to: { name: "ProfileChecksList" } },
      ];
  }
}

export function entityTitle(item) {
  if (item.bpartner) return `Деловой партнер #${item.bpartner}`;
  else if (item.declaration) return `Декларация #${item.declaration}`;
  else return `Личный листок #${item.profile}`;
}

export const hitsStatus = {
  solid: "Выявлено совпадение",
  potential: "Выявлено возможное совпадение",
};

export const hitGroups = {
  phys: "Физическое лицо",
  legal: "Юридическое лицо",
  address: "Адрес",
};
