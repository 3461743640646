import mutations from "@/store/mutations";
import { wrapCommitDispatch, addLinks } from "@/helpers/global";
import { fetchAPI } from "@/helpers/fetch";
import { chartSettings } from "@/components/Risk/Chart/settings";
import { checkPermissions } from "@/auth/auth";
import perm from "@/auth/permissions";
import { typeRiskLevel } from "@/helpers/text.js";
import { statesRisk } from "@/helpers/states";

function additionChart(obj) {
  obj.datasets.forEach((el, i) => {
    el.data.forEach((risk, j) => {
      el.data[j] = {
        ...risk,
        x: Math.round(100 * risk.x), // умножим значения на 100, чтобы переверсти в %
        y: Math.round(100 * risk.y),
      };
    });
    obj.datasets[i] = { ...el, ...chartSettings[el.level] }; // добавим настройки
  });
  return obj;
}

function additionRiskList(obj) {
  return Array.isArray(obj)
    ? obj.map((el) => ({
        ...el,
        stateT: statesRisk[el.state],
        risk_probabilityT: el.risk_probability
          ? Math.round(100 * el.risk_probability) + "%"
          : "",
        risk_impactT: el.risk_impact
          ? Math.round(100 * el.risk_impact) + "%"
          : "",
        risk_levelT: typeRiskLevel(el.risk_level),
      }))
    : [];
}

function additionMatrixFormRisks(obj) {
  return Array.isArray(obj)
    ? obj.map((el) => ({
        ...el,
        risk_levelT: typeRiskLevel(el.risk_level),
      }))
    : [];
}

const {
  RISK_MATRIX,
  RISK_MATRIX_COUNT,
  RISK_MATRIX_LOADING,
  RISK_MATRIX_PAGE,
  RISKS,
  RISKS_PAGE,
  RISKS_COUNT,
  RISKS_LOADING,
  RISK_FORM,
  RISK_FORM_LOADING,
  MATRIX_FORM,
  MATRIX_FORM_LOADING,
  MATRIX_FORM_CHANGE,
  MATRIX_FORM_CHANGE_LOADING,
  MATRIX_FORM_CHANGE_COUNT,
  MATRIX_FORM_RISKS,
  MATRIX_FORM_RISKS_COUNT,
  MATRIX_FORM_RISKS_LOADING,
  RISK_AVAILABLE,
  RISK_AVAILABLE_COUNT,
  RISK_AVAILABLE_LOADING,
  // checks
  MATRIX_FORM_CHECKS,
  MATRIX_FORM_CHECKS_COUNT,
  MATRIX_FORM_CHECKS_LOADING,
  // chart
  RISK_CHART,
  RISK_CHART_LOADING,
} = mutations;

const risk = {
  namespaced: true,

  state: {
    matrix: [],
    matrixCount: 0,
    matrixLoading: false,
    matrixPage: 1,
    risks: [],
    risksCount: 0,
    risksPage: 1,
    risksLoading: false,
    riskForm: {},
    riskFormLoading: false,
    matrixForm: {},
    matrixFormLoading: false,
    matrixFormChange: [],
    matrixFormChangeLoading: false,
    matrixFormChangeCount: 0,
    matrixFormRisks: [],
    matrixFormRisksLoading: false,
    matrixFormRisksCount: 0,
    availableRisk: [],
    availableRiskCount: 0,
    availableRiskLoading: false,
    // checks
    matrixFormChecks: [],
    matrixFormChecksCount: 0,
    matrixFormChecksLoading: false,
    // chart
    riskChart: [],
    riskChartLoading: false,
  },

  getters: {
    matrix: ({ matrix }) => matrix,
    matrixCount: ({ matrixCount }) => matrixCount,
    matrixLoading: ({ matrixLoading }) => matrixLoading,
    matrixPage: ({ matrixPage }) => matrixPage,
    risks: ({ risks }) => risks,
    risksCount: ({ risksCount }) => risksCount,
    risksPage: ({ risksPage }) => risksPage,
    risksLoading: ({ risksLoading }) => risksLoading,
    riskForm: ({ riskForm }) => riskForm,
    riskFormLoading: ({ riskFormLoading }) => riskFormLoading,
    matrixForm: ({ matrixForm }) => matrixForm,
    matrixFormLoading: ({ matrixFormLoading }) => matrixFormLoading,
    matrixFormChange: ({ matrixFormChange }) => matrixFormChange,
    matrixFormChangeLoading: ({ matrixFormChangeLoading }) =>
      matrixFormChangeLoading,
    matrixFormChangeCount: ({ matrixFormChangeCount }) => matrixFormChangeCount,
    matrixFormRisks: ({ matrixFormRisks }) => matrixFormRisks,
    matrixFormRisksLoading: ({ matrixFormRisksLoading }) =>
      matrixFormRisksLoading,
    matrixFormRisksCount: ({ matrixFormRisksCount }) => matrixFormRisksCount,
    availableRisk: ({ availableRisk }) => availableRisk,
    availableRiskCount: ({ availableRiskCount }) => availableRiskCount,
    availableRiskLoading: ({ availableRiskLoading }) => availableRiskLoading,
    // checks
    matrixFormChecks: ({ matrixFormChecks }) => matrixFormChecks,
    matrixFormChecksCount: ({ matrixFormChecksCount }) => matrixFormChecksCount,
    matrixFormChecksLoading: ({ matrixFormChecksLoading }) =>
      matrixFormChecksLoading,
    // chart
    riskChart: ({ riskChart }) => riskChart,
    riskChartLoading: ({ riskChartLoading }) => riskChartLoading,
  },

  mutations: {
    // matrix list
    [RISK_MATRIX](state, value) {
      state.matrix = value;
    },
    [RISK_MATRIX_COUNT](state, value) {
      state.matrixCount = value;
    },
    [RISK_MATRIX_PAGE](state, value) {
      state.matrixPage = value;
    },
    [RISK_MATRIX_LOADING](state, value) {
      state.matrixLoading = value;
    },
    // risk list
    [RISKS_PAGE](state, value) {
      state.risksPage = value;
    },
    [RISKS](state, value) {
      state.risks = value;
    },
    [RISKS_COUNT](state, value) {
      state.risksCount = value;
    },
    [RISKS_LOADING](state, value) {
      state.risksLoading = value;
    },
    // risk form
    [RISK_FORM](state, value) {
      state.riskForm = value;
    },
    [RISK_FORM_LOADING](state, value) {
      state.riskFormLoading = value;
    },
    // matrix form
    [MATRIX_FORM](state, value) {
      state.matrixForm = value;
    },
    [MATRIX_FORM_LOADING](state, value) {
      state.matrixFormLoading = value;
    },
    // matrix form change
    [MATRIX_FORM_CHANGE](state, value) {
      state.matrixFormChange = value;
    },
    [MATRIX_FORM_CHANGE_LOADING](state, value) {
      state.matrixFormChangeLoading = value;
    },
    [MATRIX_FORM_CHANGE_COUNT](state, value) {
      state.matrixFormChangeCount = value;
    },
    // matrix form risks
    [MATRIX_FORM_RISKS](state, value) {
      state.matrixFormRisks = value;
    },
    [MATRIX_FORM_RISKS_COUNT](state, value) {
      state.matrixFormRisksCount = value;
    },
    [MATRIX_FORM_RISKS_LOADING](state, value) {
      state.matrixFormRisksLoading = value;
    },
    // available risk
    [RISK_AVAILABLE](state, value) {
      state.availableRisk = value;
    },
    [RISK_AVAILABLE_COUNT](state, value) {
      state.availableRiskCount = value;
    },
    [RISK_AVAILABLE_LOADING](state, value) {
      state.availableRiskLoading = value;
    },
    // checks
    [MATRIX_FORM_CHECKS](state, value) {
      state.matrixFormChecks = value;
    },
    [MATRIX_FORM_CHECKS_COUNT](state, value) {
      state.matrixFormChecksCount = value;
    },
    [MATRIX_FORM_CHECKS_LOADING](state, value) {
      state.matrixFormChecksLoading = value;
    },
    // chart
    [RISK_CHART](state, value) {
      state.riskChart = value;
    },
    [RISK_CHART_LOADING](state, value) {
      state.riskChartLoading = value;
    },
  },

  actions: {
    // запрос с сервера списка матриц рисков
    async updateMatrix(context) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/matrices/", {
            page: context.getters.matrixPage,
            page_size: 20,
          });
          context.commit(RISK_MATRIX, response.results);
          context.commit(RISK_MATRIX_COUNT, response.count);
        },
        RISK_MATRIX_LOADING
      );
    },

    // запрос с сервера списка рисков
    async updateRisks(context) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/risks/", {
            page: context.getters.risksPage,
            page_size: 20,
          });
          context.commit(RISKS, additionRiskList(response.results));
          context.commit(RISKS_COUNT, response.count);
        },
        RISKS_LOADING
      );
    },

    // удаление риска
    async removeRisk(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(`/risks/${data.id}/`, null, "DELETE");
          return `Риск ${data.risk_num || ""}-${
            data.activity_num || ""
          } удален`;
        },
        RISKS_LOADING
      );
    },

    // запрос риска по id
    async updateRiskById(context, id) {
      await wrapCommitDispatch(
        context,
        async () => {
          const risk = await fetchAPI(`/risks/${id}/`);
          context.commit(RISK_FORM, risk);
        },
        RISK_FORM_LOADING
      );
    },

    // запрос матрицы по id
    async updateMatrixById(context, id) {
      await wrapCommitDispatch(
        context,
        async () => {
          const matrix = await fetchAPI(`/matrices/${id}/`);
          context.commit(MATRIX_FORM, matrix);
        },
        MATRIX_FORM_LOADING
      );
    },

    // создание новой матрицы
    async createMatrix(context) {
      context.commit(MATRIX_FORM, {}); // drop current form
      await wrapCommitDispatch(
        context,
        async () => {
          const matrix = await fetchAPI("/matrices/", null, "POST");
          context.commit(MATRIX_FORM, matrix);
          return "Создана новая матрица";
        },
        MATRIX_FORM_LOADING
      );
    },

    // создание нового риска
    async createRisk(context) {
      context.commit(RISK_FORM, {}); // drop current form
      await wrapCommitDispatch(
        context,
        async () => {
          const risk = await fetchAPI("/risks/", null, "POST");
          context.commit(RISK_FORM, risk);
          return "Риск создан";
        },
        RISK_FORM_LOADING
      );
    },

    // изменение существуюещего риска
    async changeRisk(context, data) {
      await wrapCommitDispatch(context, async () => {
        await fetchAPI(`/risks/${data.id}/`, null, "PATCH", data.params);
        return "Риск изменен";
      });
    },

    // изменить статус риска
    async changeStateRisk(context, data) {
      await wrapCommitDispatch(context, async () => {
        const risk = await fetchAPI(
          `/risks/${data.id}/transition/`,
          null,
          "PATCH",
          addLinks({ state: data.state })
        );
        context.commit(RISK_FORM, risk);
        return data.msg;
      });
    },

    // изменить статус матрицы
    async changeStateMatrix(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const matrix = await fetchAPI(
            `/matrices/${data.id}/transition/`,
            null,
            "PATCH",
            addLinks({ state: data.state })
          );
          context.commit(MATRIX_FORM, matrix);
          return data.msg;
        },
        MATRIX_FORM_LOADING
      );
    },

    // установка текущей страницы списка матриц рисков
    setMatrixPage({ commit }, page) {
      commit(RISK_MATRIX_PAGE, page);
    },

    // установка текущей страницы списка рисков
    setRisksPage({ commit }, page) {
      commit(RISKS_PAGE, page);
    },

    // запрос с сервера журнала изменений матрицы рисков
    async updateMatrixFormChange(context, { id, page }) {
      await wrapCommitDispatch(
        context,
        async () => {
          const change = await fetchAPI("/matrix-events/", {
            matrix_id: id,
            page,
          });
          context.commit(MATRIX_FORM_CHANGE, change.results);
          context.commit(MATRIX_FORM_CHANGE_COUNT, change.count);
        },
        MATRIX_FORM_CHANGE_LOADING
      );
    },

    // запрос с сервера проверок матрицы рисков
    async updateMatrixFormChecks(context, { id, page }) {
      await wrapCommitDispatch(
        context,
        async () => {
          const checks = await fetchAPI(`/matrices/${id}/checks/`, { page });
          context.commit(MATRIX_FORM_CHECKS, checks.results);
          context.commit(MATRIX_FORM_CHECKS_COUNT, checks.count);
        },
        MATRIX_FORM_CHECKS_LOADING
      );
    },

    // создать проверку для матрицы рисков
    async createMatrixFormChecks(context, { id, assignee }) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(`/matrices/${id}/checks/`, null, "POST", { assignee });
          return "Проверка назначена";
        },
        MATRIX_FORM_CHECKS_LOADING
      );
    },

    // удалить проверку для матрицы рисков
    async removeMatrixFormChecks(context, { matrixId, checkId }) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/matrices/${matrixId}/checks/${checkId}/`,
            null,
            "DELETE"
          );
          return "Назначение удалено";
        },
        MATRIX_FORM_CHECKS_LOADING
      );
    },

    // изменить статус проверки для матрицы рисков
    async changeStateCheck(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/matrices/${data.matrixId}/checks/${data.checkId}/transition/`,
            null,
            "PATCH",
            addLinks({ state: data.state })
          );
          return data.msg;
        },
        MATRIX_FORM_LOADING
      );
    },

    // запрос с сервера недорисков для матрицы
    async updateMatrixFormRisks(context, id) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(`/matrices/${id}/risks/`, {
            page: context.getters.risksPage,
          });
          context.commit(
            MATRIX_FORM_RISKS,
            additionMatrixFormRisks(response.results)
          );
          context.commit(MATRIX_FORM_RISKS_COUNT, response.count);
        },
        MATRIX_FORM_RISKS_LOADING
      );
    },

    // изменить недориск
    async changeMatrixFormRisks(context, data) {
      await wrapCommitDispatch(context, async () => {
        if (!checkPermissions(perm.change_wa_matrix))
          return "Нет прав на внесение изменений";
        await fetchAPI(
          `/matrices/${data.matrixId}/risks/${data.riskId}/`,
          null,
          "PATCH",
          data.params
        );
        return "Изменения внесены";
      });
    },

    // удаление из матрицы недориска
    async removeMatrixFormRisk(context, { matrixId, riskId }) {
      await wrapCommitDispatch(context, async () => {
        await fetchAPI(
          `/matrices/${matrixId}/risks/${riskId}/`,
          null,
          "DELETE"
        );
        return `Риск ${riskId} удален из матрицы`;
      });
    },

    // подобрать доступные риски для матриц
    async updateAvailableRisks(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/risks/", data);
          context.commit(RISK_AVAILABLE, response.results);
          context.commit(RISK_AVAILABLE_COUNT, response.count);
        },
        RISK_AVAILABLE_LOADING
      );
    },

    // добавить риск в матрицу
    async addMatrixFormRisk(context, { matrixId, riskId }) {
      await wrapCommitDispatch(context, async () => {
        const risk = await fetchAPI(
          `/matrices/${matrixId}/risks/`,
          null,
          "POST",
          { risk: riskId }
        );
        context.commit(RISK_FORM, risk);
        return `Риск ${riskId} добавлен в матрицу`;
      });
    },

    // chart
    async updateRiskChart(context) {
      await wrapCommitDispatch(
        context,
        async () => {
          const chart = await fetchAPI("/risks/chart/");
          context.commit(RISK_CHART, additionChart(chart));
        },
        RISK_CHART_LOADING
      );
    },
  },
};
export default risk;
