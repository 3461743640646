import { formatDate, fmtDateShort, fmtDate } from "@/helpers/date";
import { mapActions, mapGetters } from "vuex";
import links from "@/router/links";
import { limitBound, CONST_NONAME } from "@/helpers/text";
import { statesEducUserText } from "@/helpers/states";
export default {
  name: "ProfileEducationProgram",
  components: {},
  data: () => ({
    fmtDateShort,
    CONST_NONAME,
    fmtDate,
    links,
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    fields: [{
      key: "index",
      label: "№ п/п",
      class: "text-start"
    }, {
      key: "course_id",
      label: "UID"
    }, {
      key: "title",
      label: "Наименование",
      sortable: false
    }, {
      key: "location",
      label: "Адрес",
      sortable: false
    }, {
      key: "state4profile",
      label: "Статус"
    }, {
      key: "date_end_formal",
      label: "Дата завершения (план)",
      sortable: false,
      class: "text-nowrap text-right"
    }]
  }),
  computed: {
    ...mapGetters("education", ["coursUser", "coursUserLoading", "coursUserCount"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateUserCourse({
          page: value
        });
      }
    }
  },
  methods: {
    ...mapActions("education", ["updateUserCourse"]),
    formatDate,
    statesEducUserText,
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.coursUserCount / this.perPage, this.currentPage);
      this.updateUserCourse({
        page: this.currentPage
      });
    }
  }
};