import mutations from "@/store/mutations";
import { fetchAPI } from "@/helpers/fetch";
import { wrapCommitDispatch } from "@/helpers/global";
import { formatObjDate } from "@/helpers/date";

function handlerNotificationList(arr) {
  return arr.map((el) => ({ ...el, deadlineObj: formatObjDate(el.deadline) }));
}

const {
  // adminNotification
  ADMIN_NOTIFICATION_LIST,
  ADMIN_NOTIFICATION_COUNT,
  ADMIN_NOTIFICATION_LOADING,
  ADMIN_NOTIFICATION_ITEM,
  ADMIN_NOTIFICATION_ITEM_LOADING,
  // dataAdress
  DATA_ADDRESS,
  DATA_ADDRESS_LOADING,
  // автосверка
  AUTOCHECKS_LIST,
  AUTOCHECKS_COUNT,
  AUTOCHECKS_LOADING,
  AUTOCHECKS_ENTITY,
  AUTOCHECKS_ENTITY_LOADING,
  AUTOCHECKS_HITS_LIST,
  AUTOCHECKS_HITS_COUNT,
  AUTOCHECKS_HITS_LOADING,
  AUTOCHECKS_MESSAGE,
  AUTOCHECKS_MESSAGE_LOADING,
} = mutations;

const administration2 = {
  namespaced: true,
  state: {
    // adminNotification
    adminNotificationList: [],
    adminNotificationCount: 0,
    adminNotificationLoading: false,
    adminNotificationItem: {},
    adminNotificationItemLoading: false,
    // dataAdress
    dataAddress: {},
    dataAddressLoading: false,
    // автосверка
    autochecksList: [],
    autochecksCount: 0,
    autochecksLoading: false,
    autochecksEntity: {},
    autochecksEntityLoading: false,
    autochecksHitsList: [],
    autochecksHitsCount: 0,
    autochecksHitsLoading: false,
    autochecksMessage: {},
    autochecksMessageLoading: false,
  },
  getters: {
    // adminNotification
    adminNotificationList: ({ adminNotificationList }) => adminNotificationList,
    adminNotificationCount: ({ adminNotificationCount }) =>
      adminNotificationCount,
    adminNotificationLoading: ({ adminNotificationLoading }) =>
      adminNotificationLoading,
    adminNotificationItem: ({ adminNotificationItem }) => adminNotificationItem,
    adminNotificationItemLoading: ({ adminNotificationItemLoading }) =>
      adminNotificationItemLoading,
    // dataAdress
    dataAddress: ({ dataAddress }) => {
      if (
        typeof dataAddress === "object" &&
        Array.isArray(dataAddress.suggestions)
      )
        return dataAddress.suggestions.map((s) => s.value);
      return [];
    },
    dataAddressLoading: ({ dataAddressLoading }) => dataAddressLoading,
    // автосверка
    autochecksList: ({ autochecksList }) => autochecksList,
    autochecksCount: ({ autochecksCount }) => autochecksCount,
    autochecksLoading: ({ autochecksLoading }) => autochecksLoading,
    autochecksEntity: ({ autochecksEntity }) => autochecksEntity,
    autochecksEntityLoading: ({ autochecksEntityLoading }) =>
      autochecksEntityLoading,
    autochecksHitsList: ({ autochecksHitsList }) => autochecksHitsList,
    autochecksHitsCount: ({ autochecksHitsCount }) => autochecksHitsCount,
    autochecksHitsLoading: ({ autochecksHitsLoading }) => autochecksHitsLoading,
    autochecksMessage: ({ autochecksMessage }) => autochecksMessage,
    autochecksMessageLoading: ({ autochecksMessageLoading }) =>
      autochecksMessageLoading,
  },
  mutations: {
    // adminNotification
    [ADMIN_NOTIFICATION_LIST](state, value) {
      state.adminNotificationList = value;
    },
    [ADMIN_NOTIFICATION_COUNT](state, value) {
      state.adminNotificationCount = value;
    },
    [ADMIN_NOTIFICATION_LOADING](state, value) {
      state.adminNotificationLoading = value;
    },
    [ADMIN_NOTIFICATION_ITEM](state, value) {
      state.adminNotificationItem = value;
    },
    [ADMIN_NOTIFICATION_ITEM_LOADING](state, value) {
      state.adminNotificationItemLoading = value;
    },
    // dataAdress
    [DATA_ADDRESS](state, value) {
      state.dataAddress = value;
    },
    [DATA_ADDRESS_LOADING](state, value) {
      state.dataAddressLoading = value;
    },
    // автосверка
    [AUTOCHECKS_LIST](state, value) {
      state.autochecksList = value;
    },
    [AUTOCHECKS_COUNT](state, value) {
      state.autochecksCount = value;
    },
    [AUTOCHECKS_LOADING](state, value) {
      state.autochecksLoading = value;
    },
    [AUTOCHECKS_ENTITY](state, value) {
      state.autochecksEntity = value;
    },
    [AUTOCHECKS_ENTITY_LOADING](state, value) {
      state.autochecksEntityLoading = value;
    },
    [AUTOCHECKS_HITS_LIST](state, value) {
      state.autochecksHitsList = value;
    },
    [AUTOCHECKS_HITS_COUNT](state, value) {
      state.autochecksHitsCount = value;
    },
    [AUTOCHECKS_HITS_LOADING](state, value) {
      state.autochecksHitsLoading = value;
    },
    [AUTOCHECKS_MESSAGE](state, value) {
      state.autochecksMessage = value;
    },
    [AUTOCHECKS_MESSAGE_LOADING](state, value) {
      state.autochecksMessageLoading = value;
    },
  },
  actions: {
    // adminNotification List
    async updateAdminNotification(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/notif-settings/", data);
          context.commit(
            ADMIN_NOTIFICATION_LIST,
            handlerNotificationList(response.results)
          );
          context.commit(ADMIN_NOTIFICATION_COUNT, response.count);
        },
        ADMIN_NOTIFICATION_LOADING
      );
    },
    // adminNotification Item
    async changeAdminNotificationItem(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/notif-settings/${data.id}/`,
            null,
            "PATCH",
            data.body
          );
          context.commit(ADMIN_NOTIFICATION_ITEM, response);
          return "Изменения сохранены";
        },
        ADMIN_NOTIFICATION_ITEM_LOADING
      );
    },
    // dataAdress
    async updateDataAdress(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const url = process.env.VUE_APP_DADATA_URL;
          const params = {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Token " + process.env.VUE_APP_DADATA,
            },
            body: JSON.stringify({ query: data }),
          };
          const response = await fetch(url, params);
          const text = await response.text();
          if (!response.ok) console.log(`ошибка ${response.status} ${text}`);
          else context.commit(DATA_ADDRESS, text ? JSON.parse(text) : {});
        },
        DATA_ADDRESS_LOADING
      );
    },
    // автосверка
    // list update
    async updateAutochecks(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/autochecks/", data);
          context.commit(AUTOCHECKS_LIST, response.results);
          context.commit(AUTOCHECKS_COUNT, response.count);
        },
        AUTOCHECKS_LOADING
      );
    },
    // post autocheck entity
    async postAutochecksEntity(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          context.commit(AUTOCHECKS_ENTITY, {}); // обнулим
          const response = await fetchAPI(
            `/${data.endpoint}/${data.id}/autocheck/`,
            null,
            "POST"
          );
          context.commit(AUTOCHECKS_ENTITY, response);
          return response.hits_count
            ? `Автосверка выполнена, выявлены совпадения (${response.hits_count})`
            : "Автосверка выполнена, совпадений не выявлено";
        },
        AUTOCHECKS_ENTITY_LOADING
      );
    },
    // entity update
    async updateAutochecksEntity(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(`/autochecks/${data.id}/`);
          context.commit(AUTOCHECKS_ENTITY, response);
        },
        AUTOCHECKS_ENTITY_LOADING
      );
    },
    // list hits update
    async updateAutochecksHits(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/autochecks/${data.id}/hits/`,
            data.params
          );
          context.commit(AUTOCHECKS_HITS_LIST, response.results);
          context.commit(AUTOCHECKS_HITS_COUNT, response.count);
        },
        AUTOCHECKS_HITS_LOADING
      );
    },
    // autochecks message update
    async updateAutochecksMessage(context) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/autochecks-message");
          context.commit(AUTOCHECKS_MESSAGE, response);
        },
        AUTOCHECKS_MESSAGE_LOADING
      );
    },
  },
};

export default administration2;
