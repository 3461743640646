import { unref as _unref, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "overflow-hidden border-0 shadow-sm rounded-bottom"
};
const _hoisted_2 = {
  class: "d-flex p-1 align-items-center justify-content-end"
};
const _hoisted_3 = {
  class: "me-4"
};
import { computed, toRefs, ref } from "vue";
import { limitBound } from "@/helpers/text";
export default {
  __name: 'TablePagination',
  props: {
    currentPage: Number,
    perPage: Number,
    count: Number
  },
  emits: ["update:currentPage"],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const {
      perPage,
      count
    } = toRefs(props);
    const emit = __emit;
    const currentPage = computed({
      get: () => props.currentPage,
      set: value => emit("update:currentPage", value)
    });
    const gotoPage = ref(1);
    function changePage() {
      currentPage.value = limitBound(Number(gotoPage.value), 1, count.value / perPage.value, currentPage.value);
    }
    return (_ctx, _cache) => {
      const _component_b_pagination = _resolveComponent("b-pagination");
      const _component_b_form_input = _resolveComponent("b-form-input");
      const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
      const _component_b_button = _resolveComponent("b-button");
      const _component_b_input_group_append = _resolveComponent("b-input-group-append");
      const _component_b_input_group = _resolveComponent("b-input-group");
      const _component_b_card_header = _resolveComponent("b-card-header");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_card_header, {
        "header-class": "py-0 m-0"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_withDirectives(_createVNode(_component_b_pagination, {
          class: "mb-0",
          modelValue: currentPage.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => currentPage.value = $event),
          "total-rows": _unref(count),
          "per-page": _unref(perPage),
          size: "sm",
          "first-number": "",
          "last-number": "",
          "hide-goto-end-buttons": ""
        }, null, 8, ["modelValue", "total-rows", "per-page"]), [[_vShow, _unref(count) > _unref(perPage)]])]), _createElementVNode("div", null, [_withDirectives(_createVNode(_component_b_input_group, {
          prepend: "Перейти к",
          size: "sm"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            modelValue: gotoPage.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => gotoPage.value = $event),
            style: {
              "width": "3em"
            },
            oninput: "this.value = this.value.replace(/[^0-9]/g, '');"
          }, null, 8, ["modelValue"]), _createVNode(_component_b_input_group_append, null, {
            default: _withCtx(() => [_createVNode(_component_b_button, {
              variant: "outline-primary-muted",
              onClick: changePage,
              size: "sm"
            }, {
              default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
                icon: ['far', 'arrow-alt-circle-right'],
                "fixed-width": ""
              })]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 512), [[_vShow, _unref(count) > _unref(perPage)]])])])]),
        _: 1
      })]);
    };
  }
};