import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-067e92c4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "pagination-wrapper"
};
const _hoisted_2 = ["onSubmit"];
const _hoisted_3 = ["max"];
import { ref, watch } from "vue";
export default {
  __name: 'CustomPagination',
  props: {
    totalRows: {
      type: Number,
      required: true
    }
  },
  emits: ["onChangePage"],
  setup(__props, {
    emit: __emit
  }) {
    const perPage = ref(20);
    const currentPage = ref(1);
    const gotoPage = ref(1);
    const emit = __emit;
    const props = __props;
    watch(currentPage, newValue => {
      emit("onChangePage", newValue);
    });
    const changePage = () => {
      const newPage = Number(gotoPage.value);
      currentPage.value = newPage;
      emit("onChangePage", newPage);
    };
    return (_ctx, _cache) => {
      const _component_b_pagination = _resolveComponent("b-pagination");
      const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
      const _component_b_button = _resolveComponent("b-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_pagination, {
        class: "mb-0",
        modelValue: currentPage.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => currentPage.value = $event),
        "total-rows": props.totalRows,
        "per-page": perPage.value,
        size: "sm",
        "first-number": "",
        "last-number": "",
        "hide-goto-end-buttons": ""
      }, null, 8, ["modelValue", "total-rows", "per-page"]), _createElementVNode("form", {
        class: "d-flex align-items-center gap-2",
        onSubmit: _withModifiers(changePage, ["prevent"])
      }, [_createElementVNode("label", null, [_createTextVNode(" Перейти к "), _withDirectives(_createElementVNode("input", {
        type: "number",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => gotoPage.value = $event),
        min: "1",
        max: Math.ceil(props.totalRows / perPage.value)
      }, null, 8, _hoisted_3), [[_vModelText, gotoPage.value]])]), _createVNode(_component_b_button, {
        variant: "outline-primary-muted",
        onClick: changePage,
        size: "sm",
        type: "submit"
      }, {
        default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
          icon: ['far', 'arrow-alt-circle-right'],
          "fixed-width": ""
        })]),
        _: 1
      })], 40, _hoisted_2)]);
    };
  }
};