import { mapGetters, mapActions } from "vuex";
import { formatDate, fmtDateShort } from "@/helpers/date";
import { statesDeclarations } from "@/helpers/states";
import links from "@/router/links";
import { limitBound } from "@/helpers/text";
export default {
  name: "ProfileDeclarations",
  components: {},
  data: () => ({
    showModalRemove: false,
    showModalCopy: false,
    currentId: null,
    links,
    perPage: 10,
    gotoPage: 1,
    fields: [{
      key: "index",
      label: "№ п/п"
    }, {
      key: "id",
      label: "UID",
      formatter: value => "Декларация #" + value,
      class: "decl-number"
    }, {
      key: "created_at",
      label: "Дата создания",
      formatter: value => formatDate(value, fmtDateShort)
    }, {
      key: "submitted_at",
      label: "Дата регистрации",
      formatter: value => formatDate(value, fmtDateShort)
    }, {
      key: "state",
      label: "Статус",
      formatter: value => statesDeclarations[value]
    }, {
      key: "completed_at",
      label: "Дата завершения (факт)",
      formatter: value => formatDate(value, fmtDateShort)
    }, {
      key: "changeDecl",
      label: "",
      class: "text-center"
    }, {
      key: "cloneDecl",
      label: "",
      class: "text-center"
    }, {
      key: "removeDecl",
      label: "",
      class: "text-center"
    }]
  }),
  computed: {
    ...mapGetters("declarations", ["declarationsObj", "declarationsCount", "declarationsLoading", "declarationsPage"]),
    currentPageModel: {
      get() {
        return this.declarationsPage;
      },
      set(value) {
        this.setDeclarationsPage(value);
        this.requestDeclarations();
      }
    }
  },
  methods: {
    ...mapActions("declarations", ["fetchDeclarations4User", "setDeclarationsPage", "cloneDeclaration"]),
    ...mapActions("declarationForm", ["removeDeclaration"]),
    ...mapActions(["showNotify"]),
    requestDeclarations() {
      const page = this.declarationsPage;
      this.fetchDeclarations4User({
        sortBy: "state_modified_at",
        sortDesc: true,
        filters: {},
        page: page
      });
    },
    changePage() {
      const targetPage = limitBound(Number(this.gotoPage), 1, this.declarationsCount / this.perPage, this.declarationsPage);
      this.setDeclarationsPage(targetPage);
      this.requestDeclarations();
    },
    removeDecl(id) {
      this.currentId = id;
      this.showModalRemove = true;
    },
    async doRemove() {
      await this.removeDeclaration({
        id: this.currentId
      });
      this.setDeclarationsPage(1);
      this.requestDeclarations();
    },
    cloneDecl(id) {
      this.currentId = id;
      this.showModalCopy = true;
    },
    async doCopy() {
      await this.cloneDeclaration(this.currentId);
      this.setDeclarationsPage(1);
      this.requestDeclarations();
    }
  }
};