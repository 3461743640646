import "core-js/modules/es.array.push.js";
import BreadCrumbToHome from "@/components/BreadCrumbToHome";
import EducationProgramInner from "./EducationProgramInner";
import Report from "./Reports/EducationProgramReport.vue";
import EducationProgramOut from "./EducationProgramOut";
import TestChecks from "./TestChecks.vue";
import { mapActions, mapGetters } from "vuex";
import theNotification from "@/components/theNotification";
import links from "@/router/links";
export default {
  name: "EducationArea",
  components: {
    BreadCrumbToHome,
    EducationProgramInner,
    EducationProgramOut,
    theNotification,
    Report,
    TestChecks
  },
  data: () => ({
    tabIndex: 0
  }),
  async created() {
    this.updateTestsCheck({
      page: 1
    });
    await this.updateCourses({
      sortBy: null,
      sortDesc: true,
      page: 1,
      pageSize: 20,
      filters: {
        edu_type: "internal",
        is_new: this.onlyNew
      }
    });
    await this.updateCourses({
      sortBy: null,
      sortDesc: true,
      page: 1,
      pageSize: 20,
      filters: {
        edu_type: "external",
        is_new: this.onlyNew
      }
    });
    this.setOnlyNew(null); // обнулимся
  },

  computed: {
    ...mapGetters("education", ["getCourse", "coursOutCount", "coursCount", "testsCheckCount"]),
    ...mapGetters("homeStats", ["onlyNew"])
  },
  methods: {
    ...mapActions("education", ["updateCourses", "createCourse", "updateTestsCheck"]),
    ...mapActions("homeStats", ["setOnlyNew"]),
    async clickCreate() {
      await this.createCourse();
      this.$router.push(links.educationProgramAdd + this.getCourse.id);
    }
  }
};