import "core-js/modules/es.array.push.js";
import personalAreaCards from "./pa-cards";
import PersonalAreaCard from "@/components/PersonalArea/PersonalAreaCard.vue";
//import OrganisationsList from "@/components/Organisations/OrganisationsList.vue";
import theNotification from "@/components/theNotification";
import { mapActions, mapGetters } from "vuex";
import links from "@/router/links";
import { isAuthenticated, checkPermissions, needChangePassword } from "@/auth/auth";
import { formatDate, fmtDateMonth } from "@/helpers/date";
import { userInitials, formatName, fmtNameShort } from "@/helpers/text";
export default {
  name: "PersonalArea",
  components: {
    theNotification,
    PersonalAreaCard
  },
  data: () => ({
    userInitials,
    formatName,
    fmtNameShort,
    formatDate,
    fmtDateMonth,
    links,
    personalAreaCards,
    showAlertAutochecksMessage: false
  }),
  async created() {
    if (!isAuthenticated()) {
      this.$router.push(links.auth);
      return;
    }
    await this.updateProfileSettings();
    if (Array.isArray(this.profilSettings) && this.profilSettings.length > 0 && needChangePassword(this.profilSettings[0])) return; // task #394, если нужно менять пароль, то не отправляем запросы
    if (checkPermissions(this.$perm.access_working_area)) await this.updateStats(); // task #365
    if (checkPermissions(this.$perm.view_autocheck_notification_message)) {
      // task #543
      await this.updateAutochecksMessage();
      this.showAlertAutochecksMessage = Boolean(this.autochecksMessage?.bpartner || this.autochecksMessage?.declaration || this.autochecksMessage?.profile);
    }
    if (!checkPermissions(this.$perm.access_account)) return;
    await this.setDeclarationsPage(1);
    await this.fetchDeclarations4User();
    if (checkPermissions(this.$perm.start_my_course)) await this.updateUserCourse({
      page: 1
    });
    await this.fetchProfiles();
    if (checkPermissions(this.$perm.view_my_libmaterial)) {
      await this.updateLnaUserList();
    }
  },
  computed: {
    ...mapGetters("homeStats", ["stats", "statsLoading"]),
    ...mapGetters("education", ["coursUser", "coursUserLoading"]),
    ...mapGetters("profiles", ["profilesList", "profilesKadrLoading"]),
    ...mapGetters("declarations", ["declarationsObj", "declarationsLoading"]),
    ...mapGetters("knowledge", ["lnaUserList", "lnaUserListLoading"]),
    ...mapGetters("profile", ["profilSettings"]),
    ...mapGetters("administration2", ["autochecksMessage"])
  },
  methods: {
    ...mapActions("homeStats", ["updateStats"]),
    ...mapActions("profiles", ["fetchProfiles"]),
    ...mapActions("education", ["updateUserCourse"]),
    ...mapActions("declarations", ["fetchDeclarations4User", "setDeclarationsPage"]),
    ...mapActions("knowledge", ["updateLnaUserList"]),
    ...mapActions("profile", ["updateProfileSettings"]),
    ...mapActions("administration2", ["updateAutochecksMessage"])
  }
};