import "core-js/modules/es.array.push.js";
import DeclarationsList from "./DeclarationsList";
import theNotification from "@/components/theNotification";
import { mapActions, mapGetters } from "vuex";
import BreadCrumbToHome from "@/components/BreadCrumbToHome";
import links from "@/router/links";
export default {
  name: "DeclarationArea",
  components: {
    DeclarationsList,
    theNotification,
    BreadCrumbToHome
  },
  data: () => ({
    showCOI: true,
    // else show presents
    tabIndex: 0
  }),
  async created() {
    await this.fetchDeclarations({
      page: 1,
      filters: {
        is_new: this.onlyNew
      }
    });
    this.setOnlyNew(null); // обнулимся
  },

  computed: {
    ...mapGetters("declarations", ["declarationsObj"]),
    ...mapGetters("coi", ["coiForm"]),
    ...mapGetters("homeStats", ["onlyNew"])
  },
  methods: {
    ...mapActions("declarations", ["fetchDeclarations"]),
    ...mapActions("coi", ["createCOIForm"]),
    ...mapActions("homeStats", ["setOnlyNew"]),
    gotoAutocheck() {
      this.$router.push({
        name: "AutochecksArea",
        query: {
          type: "coid_only"
        }
      });
    },
    clickTabs(data) {
      this.tabIndex = data;
    },
    async clickCreateCoi() {
      await this.createCOIForm();
      if (this.coiForm.id) this.$router.push(links.coiForm + this.coiForm.id);
    }
  }
};