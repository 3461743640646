import { SITE_NAME } from "@/helpers/text.js";

const titles = {
  home: SITE_NAME,
  start: "Стартовая страница — " + SITE_NAME,
  auth: "Авторизация — " + SITE_NAME,
  about: "О платформе — " + SITE_NAME,
  declarations: "Декларации — " + SITE_NAME,
  cp: "КП — " + SITE_NAME,
  education: "Обучение — " + SITE_NAME,
  hotline: "Горячая линия — " + SITE_NAME,
  profile: "Личный кабинет — " + SITE_NAME,
  knowledge: "Библиотека знаний — " + SITE_NAME,
  partners: "Деловые партнеры — " + SITE_NAME,
  innerCheck: "Внутренние проверки — " + SITE_NAME,
  risk: "Оценка рисков — " + SITE_NAME,
  administration: "Администрирование — " + SITE_NAME,
  autochecks: "Автосверка — " + SITE_NAME,
  login: "Вход — " + SITE_NAME,
};

export default titles;
