import { mapGetters, mapActions } from "vuex";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
import AdminElementAdd from "./AdminElementAdd.vue";
import { limitBound } from "@/helpers/text.js";
export default {
  name: "AdminCountries",
  data: () => ({
    showModalRemove: false,
    currentItem: {},
    currentPage: 1,
    gotoPage: 1,
    perPage: 10,
    tableFields: [{
      key: "id",
      label: "#"
    }, {
      key: "short_name",
      label: "Название (краткое)"
    }, {
      key: "full_name",
      label: "Название (полное)"
    }, {
      key: "btnChange",
      label: "Редактировать",
      class: "text-center"
    }, {
      key: "btnRemove",
      label: "Удалить",
      class: "text-center"
    }],
    breadcrumbs: [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }],
    settingsAdd: {
      title: "Создать страну",
      btnTitle: "Создать",
      buttonIcon: false,
      iconType: null,
      texts: {
        short_name: {
          required: true,
          label: "Название (краткое)"
        },
        full_name: {
          required: false,
          label: "Название (полное)"
        }
      }
    },
    settingsChange: {
      title: "Редактировать страну",
      btnTitle: null,
      buttonIcon: true,
      iconType: "pencil-square",
      texts: {
        short_name: {
          required: true,
          label: "Название (краткое)"
        },
        full_name: {
          required: false,
          label: "Название (полное)"
        }
      }
    }
  }),
  components: {
    BreadCrumbToHome,
    theNotification,
    AdminElementAdd
  },
  created() {
    this.updateAdminCountry({
      page: 1
    });
  },
  methods: {
    ...mapActions("administration", ["updateAdminCountry", "createAdminCountry", "changeAdminCountry", "removeAdminCountry", "generateAdminCountryOKSM"]),
    // пагинация
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.adminCountryCount / this.perPage, this.currentPage);
      this.updateAdminCountry({
        page: this.currentPage
      });
    },
    // создать страну
    async clickCreate(data) {
      await this.createAdminCountry(data);
      this.currentPage = 1;
      await this.updateAdminCountry({
        page: 1
      });
    },
    // изменить страну
    async clickChange(data) {
      await this.changeAdminCountry(data);
      await this.updateAdminCountry({
        page: this.currentPage
      });
    },
    // сгенерировать страны на основании данных ОКСМ
    async clickOKSM() {
      await this.generateAdminCountryOKSM();
      this.currentPage = 1;
      await this.updateAdminCountry({
        page: 1
      });
    },
    // удалить страну
    async clickRemove(item) {
      this.currentItem = item;
      this.showModalRemove = true;
    },
    async doRemove() {
      await this.removeAdminCountry(this.currentItem);
      this.currentPage = 1;
      await this.updateAdminCountry({
        page: 1
      });
    }
  },
  computed: {
    ...mapGetters("administration", ["adminCountry", "adminCountryLoading", "adminCountryCount"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateAdminCountry({
          page: value
        });
      }
    }
  }
};