import { fetchAPI } from "@/helpers/fetch";
import { wrapCommitDispatch } from "@/helpers/global";
import mutations from "@/store/mutations";
import { formatName, fmtNameShort } from "@/helpers/text";
import { statesDeclarations } from "@/helpers/states";

function additionCoiList(obj) {
  return Array.isArray(obj)
    ? obj.map((el) => ({
        ...el,
        idT: `Декларация #${el.id}`,
        authorT: formatName(el.user_detail, fmtNameShort),
        decl_stateT: statesDeclarations[el.decl_state],
        proceduresT: el.procedures
          ? el.procedures.map((kp) => kp.cp_type_name).join(", ")
          : "-",
      }))
    : [];
}

const {
  COI,
  COI_COUNT,
  COI_FOR_DECLARATION,
  COI_FOR_DECLARATION_COUNT,
  COI_LOADING,
  COI_FOR_DECLARATION_LOADING,
  COI_DIRECTORY,
  COI_FORM,
  COI_FORM_LOADING,
  COI_FORM_CHANGE_LOADING,
  COI_ATTACHMENTS,
  COI_ATTACHMENTS_COUNT,
  COI_ATTACHMENTS_LOADING,
  COI_COMMENTS,
  COI_COMMENTS_COUNT,
  COI_COMMENTS_LOADING,
  // events
  COI_EVENTS,
  COI_EVENTS_COUNT,
  COI_EVENTS_LOADING,
} = mutations;

const coiStore = {
  namespaced: true,
  state: {
    coi: [],
    coiCount: 0,
    coiLoading: false,
    coiForDeclaration: [],
    coiForDeclarationCount: 0,
    coiForDeclarationLoading: false,
    coiDirectory: [], // список типов КИ
    // form
    coiForm: {},
    coiFormLoading: false,
    coiFormChangeLoading: false,
    // attach
    coiAttach: [],
    coiAttachCount: 0,
    coiAttachLoading: false,
    // comments
    coiComments: [],
    coiCommentsCount: 0,
    coiCommentsLoading: false,
    // events
    coiEvents: [],
    coiEventsCount: 0,
    coiEventsLoading: false,
  },
  getters: {
    coi: ({ coi }) => coi,
    coiCount: ({ coiCount }) => coiCount,
    coiLoading: ({ coiLoading }) => coiLoading,
    coiForDeclaration: ({ coiForDeclaration }) => coiForDeclaration,
    coiForDeclarationCount: ({ coiForDeclarationCount }) =>
      coiForDeclarationCount,
    coiForDeclarationLoading: ({ coiForDeclarationLoading }) =>
      coiForDeclarationLoading,
    coiDirectory: ({ coiDirectory }) => coiDirectory,
    // form
    coiForm: ({ coiForm }) => coiForm,
    coiFormLoading: ({ coiFormLoading }) => coiFormLoading,
    coiFormChangeLoading: ({ coiFormChangeLoading }) => coiFormChangeLoading,
    // attach
    coiAttach: ({ coiAttach }) => coiAttach,
    coiAttachCount: ({ coiAttachCount }) => coiAttachCount,
    coiAttachLoading: ({ coiAttachLoading }) => coiAttachLoading,
    // comments
    coiComments: ({ coiComments }) => coiComments,
    coiCommentsCount: ({ coiCommentsCount }) => coiCommentsCount,
    coiCommentsLoading: ({ coiCommentsLoading }) => coiCommentsLoading,
    // events
    coiEvents: ({ coiEvents }) => coiEvents,
    coiEventsCount: ({ coiEventsCount }) => coiEventsCount,
    coiEventsLoading: ({ coiEventsLoading }) => coiEventsLoading,
  },
  mutations: {
    [COI](state, value) {
      state.coi = value;
    },
    [COI_COUNT](state, value) {
      state.coiCount = Number(value);
    },
    [COI_LOADING](state, value) {
      state.coiLoading = value;
    },
    [COI_FOR_DECLARATION](state, value) {
      state.coiForDeclaration = value;
    },
    [COI_FOR_DECLARATION_COUNT](state, value) {
      state.coiForDeclarationCount = Number(value);
    },
    [COI_FOR_DECLARATION_LOADING](state, value) {
      state.coiForDeclarationLoading = value;
    },
    [COI_DIRECTORY](state, value) {
      state.coiDirectory = value;
    },
    // form
    [COI_FORM](state, value) {
      state.coiForm = value;
    },
    [COI_FORM_LOADING](state, value) {
      state.coiFormLoading = value;
    },
    [COI_FORM_CHANGE_LOADING](state, value) {
      state.coiFormChangeLoading = value;
    },
    // attach
    [COI_ATTACHMENTS](state, value) {
      state.coiAttach = value;
    },
    [COI_ATTACHMENTS_COUNT](state, value) {
      state.coiAttachCount = value;
    },
    [COI_ATTACHMENTS_LOADING](state, value) {
      state.coiAttachLoading = value;
    },
    // comments
    [COI_COMMENTS](state, value) {
      state.coiComments = value;
    },
    [COI_COMMENTS_COUNT](state, value) {
      state.coiCommentsCount = value;
    },
    [COI_COMMENTS_LOADING](state, value) {
      state.coiCommentsLoading = value;
    },
    // events
    [COI_EVENTS](state, value) {
      state.coiEvents = value;
    },
    [COI_EVENTS_COUNT](state, value) {
      state.coiEventsCount = Number(value);
    },
    [COI_EVENTS_LOADING](state, value) {
      state.coiEventsLoading = value;
    },
  },
  actions: {
    // запрос КИ (список)
    async fetchCoi(
      context,
      { sortBy, sortDesc, page, filters, pageSize, searchString } = {
        sortBy: null,
        sortDesc: true,
        page: 1,
        pageSize: 20,
        filters: {},
        searchString: "",
      }
    ) {
      await wrapCommitDispatch(
        context,
        async () => {
          const sortParam = sortBy ? (sortDesc ? "-" + sortBy : sortBy) : null;
          const requestParams = {
            page: page,
            page_size: pageSize,
            search_string: searchString,
          };
          if (sortParam) requestParams.sort = sortParam;
          // добавим фильтры в параметры
          Object.keys(filters).forEach((key) => {
            if (filters[key] !== null) requestParams[key] = filters[key];
          });
          const response = await fetchAPI("/conflicts/", requestParams);
          context.commit(COI, additionCoiList(response.results));
          context.commit(COI_COUNT, response.count);
        },
        COI_LOADING
      );
    },
    // запрос КИ (под конкретную декларацию)
    async fetchCoiByDeclarationId(context, { id, page }) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(`/declarations/${id}/conflicts/`, {
            page,
          });
          context.commit(COI_FOR_DECLARATION, response.results);
          context.commit(COI_FOR_DECLARATION_COUNT, response.count);
        },
        COI_FOR_DECLARATION_LOADING
      );
    },
    // смена статуса КИ
    async changeStateCoi(context, { id, state, msg }) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/conflicts/${id}/transition/`,
            null,
            "PATCH",
            { state }
          );
          context.commit(COI_FORM, response);
          return msg;
        },
        COI_FORM_CHANGE_LOADING
      );
    },
    // сброс параметров КИ (при переформировании отчета)
    dropCoi({ commit }) {
      commit(COI, []);
      commit(COI_COUNT, 0);
    },
    // запрос списка КИ
    async updateCoiDirectory(context) {
      await wrapCommitDispatch(
        context,
        async () => {
          const coiDirectory = await fetchAPI("/coi-types/reference/");
          context.commit(COI_DIRECTORY, coiDirectory);
        },
        COI_LOADING
      );
    },
    // coiForm create
    async createCOIForm(context, body = {}) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(`/conflicts/`, null, "POST", body);
          context.commit(COI_FORM, response);
          return "Создан новый КИ";
        },
        COI_FORM_LOADING
      );
    },
    // coiForm update
    async updateCOIForm(context, id) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(`/conflicts/${id}/`);
          context.commit(COI_FORM, response);
        },
        COI_FORM_LOADING
      );
    },
    // coiForm change
    async changeCOIForm(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/conflicts/${data.id}/`,
            null,
            "PATCH",
            data.params
          );
          context.commit(COI_FORM, response);
          return "Изменения для КИ внесены";
        },
        COI_FORM_CHANGE_LOADING
      );
    },
    // запрос вложений КИ
    async updateCOIAttach(context, { id, page }) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(`/conflicts/${id}/attachments/`, {
            page,
          });
          context.commit(COI_ATTACHMENTS, response.results);
          context.commit(COI_ATTACHMENTS_COUNT, response.count);
        },
        COI_ATTACHMENTS_LOADING
      );
    },
    // создать вложение к КИ
    async createCOIAttach(context, { id, formData }) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/conflicts/${id}/attachments/`,
            null,
            "POST",
            formData,
            true
          );
          return "Файл прикреплен к КИ";
        },
        COI_ATTACHMENTS_LOADING
      );
    },
    // удалить вложение к КИ
    async deleteCOIAttach(context, { id, idAttach }) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/conflicts/${id}/attachments/${idAttach}/`,
            null,
            "DELETE"
          );
          return "Файл удален";
        },
        COI_ATTACHMENTS_LOADING
      );
    },
    // запрос комментариев к КИ
    async updateCOIComments(context, { id, page }) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(`/conflicts/${id}/comments/`, {
            page,
          });
          context.commit(COI_COMMENTS, response.results);
          context.commit(COI_COMMENTS_COUNT, response.count);
        },
        COI_COMMENTS_LOADING
      );
    },
    // создать комментарий к КИ
    async createCOIComments(context, { id, body }) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(`/conflicts/${id}/comments/`, null, "POST", {
            body,
          });
          return "Комментарий к КИ создан";
        },
        COI_COMMENTS_LOADING
      );
    },
    // изменить комментарий к КИ
    async changeCOIComments(context, { id, idComment, body }) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/conflicts/${id}/comments/${idComment}/`,
            null,
            "PATCH",
            {
              body,
            }
          );
          return "Комментарий к КИ изменен";
        },
        COI_COMMENTS_LOADING
      );
    },
    // удалить комментарий к КИ
    async deleteCOIComments(context, { id, idComment }) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/conflicts/${id}/comments/${idComment}/`,
            null,
            "DELETE"
          );
          return "Комментарий к КИ удален";
        },
        COI_COMMENTS_LOADING
      );
    },
    // events update
    async updateCOIEvents(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/coi-events/", data);
          context.commit(COI_EVENTS, response.results);
          context.commit(COI_EVENTS_COUNT, response.count);
        },
        COI_EVENTS_LOADING
      );
    },
    // сброс формы КИ
    async dropCOIForm(context) {
      await wrapCommitDispatch(context, async () => {
        context.commit(COI_FORM, {});
      });
    },
  },
};

export default coiStore;
