import { fmtDate, formatDate } from "@/helpers/date";
import { invalidMinMaxDate, validate } from "@/helpers/validate";

export const endptProfile1c = "/profiles/download1c/"; // ендпоинт выгрузки ЛЛ в 1С

export const relativeDegree = {
  wife: "Жена",
  husband: "Муж",
  child: "Ребенок",
  father: "Отец",
  mother: "Мать",
  sister: "Сестра",
  brother: "Брат",
};

export function mapMaritalStatusToText(status, isMale) {
  switch (status) {
    case "single":
      return isMale ? "не женат" : "не замужем";
    case "divorced":
      return isMale ? "разведен" : "разведена";
    case "married":
      return isMale ? "женат" : "замужем";
    case "cohabitation":
      return "состою в гражданском браке";
    default:
      return "";
  }
}

export const militaryStatusDictionary = {
  notServed: "Призывник",
  served: "Военнообязанный",
  // conserved: "Нахождение в запасе",
  none: "Невоеннообязанный",
  // freeOffDuty: "Освобожден",
};
export const militaryStatusOptions = Object.entries(
  militaryStatusDictionary
).map((status) => {
  return {
    value: status[0],
    text: status[1],
  };
});
const exemptionsDictionary = {
  hero: "Герой СССР и РФ",
  cavalier: "Полный кавалер ордена Славы",
  disabiled: "Инвалид",
  bloodDonor: "Почетный донор крови",
  veteran: "Ветеран войны и боевых действий",
  civilServant: "Государственный служащий",
  singleParent: "мать-одиночка",
};

export const optionsIpSelfemployed = [
  { value: null, text: "не являюсь" },
  { value: "предприниматель", text: "предприниматель" },
  { value: "самозанятый", text: "самозанятый" },
];

export const exemptionsOptions = Object.entries(exemptionsDictionary).map(
  (exemption) => {
    return {
      value: exemption[0],
      text: exemption[1],
    };
  }
);

export function mapExemptionsToText(categories) {
  return categories
    .map((exemption) => {
      return exemptionsDictionary[exemption];
    })
    .join(", ");
}

export function profileReadonly(state) {
  return mapStateToText[state] !== mapStateToText.draft;
}

// статусы ЛЛ
export const mapStateToText = {
  draft: "Черновик",
  submitted: "Отправлен на рассмотрение",
  validated: "На рассмотрении(УК и ОБ)",
  pending: "На рассмотрении",
  approved: "Принят",
  declined: "Отклонен",
  obsolete: "Устарел",
  archived: "В архиве",
};

export function canValidate(state) {
  return mapStateToText[state] === mapStateToText.submitted;
}
export function canApprove(state) {
  return mapStateToText[state] === mapStateToText.pending;
}
// статусы проверки
export const mapCheckToText = {
  new: "Требует проверки",
  completed: "Проверен",
};
export function canCheckProfile(state) {
  return mapCheckToText[state] === mapCheckToText.new;
}

// обязательные для заполнения поля в ЛЛ
export const requiredFields = {
  last_name: "Фамилия",
  first_name: "Имя",
  middle_name: "Отчество",
  // old name
  old_names: {
    typeObject: true,
    checkEmpty: "has_old_names", // галочку поставили, а данные не ввели
    name: "Предыдущее ФИО (",
    fields: {
      last_name: "фамилия)",
      first_name: "имя)",
      middle_name: "отчество)",
    },
  },
  sex: "Пол",
  birth_date: "Число, месяц, год рождения",
  birth_place: "Место рождения",
  citizenship: "Гражданство",
  // has_other_citizenships
  other_citizenship_info: {
    typeObject: true,
    checkEmpty: "has_other_citizenships", // галочку поставили, а данные не ввели
    name: "Подтверждение иностранного гражданства (",
    fields: {
      country: "страна)",
      citizenship_document: "документ)",
    },
  },
  // другие поля
  inn: "ИНН",
  snils: "СНИЛС",
  phone_mob: "Мобильный телефон",
  email: "e-mail",
  pass_series: "Паспорт гражданина Российской Федерации (серия)",
  pass_num: "Паспорт гражданина Российской Федерации (номер",
  pass_issuer_code:
    "Паспорт гражданина Российской Федерации (код подразделения)",
  pass_issuer: "Паспорт гражданина Российской Федерации (кем выдан)",
  pass_issue_date: "Паспорт гражданина Российской Федерации (дата выдачи)",
  foreign_pass_series: {
    typeOne: true,
    checkEmpty: "has_foreign_pass", // галочку поставили, а данные не ввели
    name: "Заграничный паспорт (серия)",
  },
  foreign_pass_num: {
    typeOne: true,
    checkEmpty: "has_foreign_pass", // галочку поставили, а данные не ввели
    name: "Заграничный паспорт (номер)",
  },
  foreign_pass_issuer: {
    typeOne: true,
    checkEmpty: "has_foreign_pass", // галочку поставили, а данные не ввели
    name: "Заграничный паспорт (кем выдан)",
  },
  foreign_pass_issue_date: {
    typeOne: true,
    checkEmpty: "has_foreign_pass", // галочку поставили, а данные не ввели
    name: "Заграничный паспорт (дата выдачи)",
  },
  edu_level: "Образование",
  // administrative_record: "Привлекались ли к административной ответственности",
  // criminal_record: "Привлекались ли к уголовной ответственности",
  participation_entities: {
    typeObject: true,
    checkEmpty: "has_participation_entities", // галочку поставили, а данные не ввели
    name: "Участие в деятельности юридических лиц (",
    fields: {
      org_name: "наименование юридического лица)",
      inn: "ИНН)",
      // share: "доля в уставном капитале)",
      // participation_type: "вид владения)",
      address: "адрес юридического лица)",
    },
  },
  entity_board_positions: {
    typeObject: true,
    checkEmpty: "has_entity_board_positions", // галочку поставили, а данные не ввели
    name: "Должности в органах управления юридических лиц (",
    fields: {
      position: "должность)",
      inn: "ИНН)",
      org_name: "наименование юридического лица)",
      address: "адрес юридического лица)",
    },
  },
  addresses: {
    typeObject: true,
    name: "Место проживания и регистрации (",
    fields: {
      index: "индекс)",
      address: "адрес)",
    },
  },
  travels_abroad: {
    typeObject: true,
    name: "Пребывание за границей (",
    fields: {
      start_month: "месяц с какого времени)",
      start_year: "год с какого времени)",
      end_month: "месяц по какое время)",
      end_year: "год по какое время)",
      country: "в какой стране)",
      purpose: "цель пребывания)",
    },
  },
  edu_backgrounds: {
    typeObject: true,
    name: "Образование (",
    fields: {
      college_name: "наименование заведения)",
      department_name: "факультет/отделение)",
      start_year: "год поступления)",
      end_year: "год ухода)",
      specialization: "специальность/номер диплома/удостоверения)",
    },
  },
  add_edu_backgrounds: {
    typeObject: true,
    name: "Дополнительное образование (",
    fields: {
      college_name: "наименование заведения)",
      department_name: "факультет/отделение)",
      start_year: "год поступления)",
      end_year: "год ухода)",
      specialization: "специальность/номер диплома/удостоверения)",
    },
  },
  academic_rank: "Ученая степень, ученое звание",
  academic_products: "Научные труды и изобретения",
  foreign_languages: {
    typeObject: true,
    name: "Владение иностранными языками (",
    fields: {
      language: "название языка)",
      level: "уровень владения)",
    },
  },
  // family
  // :
  //   "Родственники написали разрешение на обработку из персональных данных",
  marital_status: "Семейное положение",
  relatives: {
    typeObject: true,
    checkEmpty: "has_relatives_agreement",
    name: "Родственники (",
    fields: {
      relation_degree: "степень родства)",
      first_name: "имя)",
      last_name: "фамилия)",
      middle_name: "отчество)",
      //birth_date: "дата рождения)",
      // org_name: "наименование места работы)",
      // inn: "ИНН места работы)",
      // position: "должность места работы)",
      //address: "адрес места жительства)",
    },
  },
  // military
  military_service: "Отношение к воинской обязанности",
  military_ranks: {
    typeObject: true,
    name: "Воинские звания (",
    fields: {
      rank: "звание)",
      military_card_number: "номер военного билета)",
      military_commissariat_name:
        "наименование военного комиссариата по месту жительства)",
      category: "род войск)",
      branch: "состав)",
    },
  },
  has_weapons: "Владеете ли вы оружием ...",
  // health
  has_privileges: "Относитесь ли Вы к льготной категории граждан ...",
  special_conditions: {
    typeObject: true,
    checkEmpty: "has_special_conditions", // галочку поставили, а данные не ввели
    name: "Медицинские ограничения и льготы (",
    fields: {
      condition: "заболевание/противопоказания)",
    },
  },
  has_special_conditions: "Имеются ли у Вас заболевания, противопоказания...",
  is_psycho:
    "Состоите (состояли) ли Вы на учете в наркологическом и/или психоневрологическом диспансере",
  // участие в органах власти
  has_elected_bodies: "Участие в центральных... выборных органах власти",
  elected_bodies: {
    typeObject: true,
    checkEmpty: "has_elected_bodies", // галочку поставили, а данные не ввели
    name: "Участие в центральных... выборных органах власти (",
    fields: {
      start_year: "год избрания)",
      end_year: "год выбытия)",
      position: "в качестве кого выбран)",
      body_name: "название)",
      location: "местонахождение)",
    },
  },
  // Участие в общественных объединениях и некоммерческих организациях
  has_ngo: "Участие в общественных объединениях и некоммерческих организациях",
  ngos: {
    typeObject: true,
    checkEmpty: "has_ngo", // галочку поставили, а данные не ввели
    name: "Участие в общественных объединениях и некоммерческих организациях (",
    fields: {
      position: "должность)",
      is_paid: "оплачиваемая)",
      org_name: "наименование)",
      inn: "ИНН)",
      address: "адрес юридического лица)",
    },
  },
  // Участие в деятельности политических партий, политических объединений
  has_political_parties:
    "Участие в деятельности политических партий, политических объединений",
  political_parties: {
    typeObject: true,
    checkEmpty: "has_political_parties", // галочку поставили, а данные не ввели
    name: "Участие в деятельности политических партий ... (",
    fields: {
      org_name: "наименование)",
      inn: "ИНН)",
      address: "адрес юридического лица)",
    },
  },
  // job
  job_records: {
    typeObject: true,
    name: "Трудовая деятельность (",
    fields: {
      start_month: "месяц поступления)",
      start_year: "год поступления)",
      org_name: "наименование)",
      inn: "ИНН)",
      position: "должность)",
      location: "местонахождение)",
      activity_domain: "сфера деятельности)",
      quit_reason: "причина увольнения)",
    },
  },
  // награды
  government_awards: {
    typeObject: true,
    checkEmpty: "has_government_awards", // галочку поставили, а данные не ввели
    name: "Какие Вы имеете награды ... (",
    fields: {
      award_name: "наименование)",
      award_date: "дата)",
      award_medium: "чем награждены)",
    },
  },
  // another
  has_enf_proceedings: "Неисполненные исполнительные производства",
  has_past_due_debts: "Просроченная задолженность ...",
  has_actual_loans: "Действующие кредитные обязательства ...",
  // sites
  has_website_exposure: "Сведения об адресах сайтов и (или) страниц сайтов ...",
  websites: {
    typeObject: true,
    checkEmpty: "has_website_exposure", // галочку поставили, а данные не ввели
    name: "Сведения об адресах сайтов и (или) страниц сайтов ... (",
    fields: {
      key: "адрес сайта)",
    },
  },
  // предприниматель
  ogrnip: {
    typeOne: true,
    checkEmpty: "ip_selfemployed", // галочку поставили, а данные не ввели
    name: "Индивидуальный предприниматель или самозанятый (ОГРНИП)",
  },
  // ответственности
  has_administrative_record: "Административная ответственность",
  administrative_record: {
    typeOne: true,
    checkEmpty: "has_administrative_record", // галочку поставили, а данные не ввели
    name: "Административная ответственность (когда и за что)",
  },
  has_criminal_record: "Уголовная ответственность",
  criminal_record: {
    typeOne: true,
    checkEmpty: "has_criminal_record", // галочку поставили, а данные не ввели
    name: "Уголовная ответственность (когда и за что)",
  },
};

// уровни образования edu_level
export const optionsEduLevel = [
  { value: "Высшее профессиональное", text: "Высшее профессиональное" },
  { value: "Среднее профессиональное", text: "Среднее профессиональное" },
  { value: "Начальное профессиональное", text: "Начальное профессиональное" },
  { value: "Среднее общее", text: "Среднее общее" },
];

// Вид владения в ЮЛ
export const optionsOwnerLevel = [
  { value: null, text: "нет" },
  { value: "прямое", text: "прямое" },
  { value: "косвенное", text: "косвенное" },
  { value: "бенефициарное владение", text: "бенефициарное владение" },
];

// registration_type (вид регистрации)
export const registrationType = {
  temporary: "временный",
  constant: "постоянный",
};

// уровень владения языками
export const languageLevelOptions = [
  "Читаю и перевожу со словарем",
  "Читаю и могу объясняться",
  "Владею свободно",
];

// задача 489 Валидация дат и символов (задача 487)
export function profileInvalidValue(obj) {
  const arr = [];
  let invalid = false;
  // полей пункта 1 «Число, месяц, год рождения» не позднее даты внесения сведений,
  invalid = invalidMinMaxDate(
    obj.birth_date,
    null,
    formatDate(new Date(), fmtDate),
    "пункт 1 «Число, месяц, год рождения» должны быть не позднее даты внесения сведений"
  );
  if (invalid) arr.push(invalid);
  // запрет на использование латинских букв и символов при заполнении полей
  // пункт 1: фамилия, имя, отчество (включая предыдущие, при их наличии)
  if (!validate({ data: obj.first_name, dataRequired: "rusText" }))
    arr.push("пункт 1: Имя");
  if (!validate({ data: obj.last_name, dataRequired: "rusText" }))
    arr.push("пункт 1: Фамилия");
  if (!validate({ data: obj.middle_name, dataRequired: "rusText" }))
    arr.push("пункт 1: Отчество");
  if (obj.has_old_names && Array.isArray(obj.old_names))
    obj.old_names.forEach((oldn, i) => {
      if (!validate({ data: oldn.first_name, dataRequired: "rusText" }))
        arr.push(`пункт 1(${i + 1}): Предыдущее имя`);
      if (!validate({ data: oldn.last_name, dataRequired: "rusText" }))
        arr.push(`пункт 1(${i + 1}): Предыдущая фамилия`);
      if (!validate({ data: oldn.middle_name, dataRequired: "rusText" }))
        arr.push(`пункт 1(${i + 1}): Предыдущее отчество`);
    });
  // поля пункта 2 «Год окончания или ухода» не позднее года внесения сведений, не ранее года рождения,
  if (Array.isArray(obj.edu_backgrounds))
    obj.edu_backgrounds.forEach((eb) => {
      invalid = invalidMinMaxDate(
        String(eb.end_year),
        formatDate(obj.birth_date, "yyyy"),
        String(new Date().getFullYear()),
        "пункт 2 «Год окончания или ухода» должен быть не позднее года внесения сведений и не ранее года рождения"
      );
      if (invalid) arr.push(invalid);
    });
  // поля пункта 3 «Год окончания или ухода» не позднее года внесения сведений, не ранее года рождения,
  if (Array.isArray(obj.add_edu_backgrounds))
    obj.add_edu_backgrounds.forEach((aeb) => {
      invalid = invalidMinMaxDate(
        String(aeb.end_year),
        formatDate(obj.birth_date, "yyyy"),
        String(new Date().getFullYear()),
        "пункт 3 «Год окончания или ухода» должен быть не позднее года внесения сведений и не ранее года рождения"
      );
      if (invalid) arr.push(invalid);
    });
  // запрет на использование латинских букв и символов при заполнении полей
  // пункт  7: наименование учреждения, организации, предприятия, министерства (ведомства), ИНН, должность
  if (Array.isArray(obj.job_records))
    obj.job_records.forEach((jr, i) => {
      if (!validate({ data: jr.org_name, dataRequired: "rusText" }))
        arr.push(
          `пункт 7(${
            i + 1
          }): Наименование учреждения, организации, предприятия, министерства (ведомства)`
        );
      if (!validate({ data: jr.organization_form, dataRequired: "rusText" }))
        arr.push(`пункт 7(${i + 1}): Организационно-правовая форма`);
      if (!validate({ data: jr.position, dataRequired: "rusText" }))
        arr.push(`пункт 7(${i + 1}): Должность`);
    });
  // поля пункту 8 «Месяц и год по какое время» не позднее месяца и года внесения сведений, не ранее месяца и года рождения,
  const invalid8 =
    "пункт 8 «Месяц и год по какое время» должны быть не позднее месяца и года внесения сведений и не ранее месяца и года рождения";
  if (Array.isArray(obj.travels_abroad))
    obj.travels_abroad.forEach((ta) => {
      if (ta.end_year && ta.end_month)
        invalid = invalidMinMaxDate(
          formatDate(`${ta.end_year}-${ta.end_month}`, "yyyy-MM"),
          formatDate(obj.birth_date, "yyyy-MM"),
          formatDate(new Date(), "yyyy-MM"),
          invalid8
        );
      else invalid = invalid8;
      if (invalid) arr.push(invalid);
    });
  // поля пункта 12 «Дата награждения» не позднее даты внесения сведений, не ранее числа, месяца и года рождения,
  if (Array.isArray(obj.government_awards))
    obj.government_awards.forEach((ga) => {
      invalid = invalidMinMaxDate(
        ga.award_date,
        obj.birth_date,
        formatDate(new Date(), fmtDate),
        "пункт 12 «Дата награждения» должна быть не позднее даты внесения сведений и не ранее числа, месяца и года рождения"
      );
      if (invalid) arr.push(invalid);
    });
  // запрет на использование латинских букв и символов при заполнении полей
  // пункт  пункт 17: наименование юридического лица
  if (
    obj.has_participation_entities &&
    Array.isArray(obj.participation_entities)
  )
    obj.participation_entities.forEach((pe, i) => {
      if (!validate({ data: pe.org_name, dataRequired: "rusText" }))
        arr.push(`пункт 17(${i + 1}): Наименование юридического лица`);
      if (!validate({ data: pe.organization_form, dataRequired: "rusText" }))
        arr.push(`пункт 17(${i + 1}): Организационно-правовая форма`);
    });
  // запрет на использование латинских букв и символов при заполнении полей
  // пункт 18: наименование юридического лица
  if (
    obj.has_entity_board_positions &&
    Array.isArray(obj.entity_board_positions)
  )
    obj.entity_board_positions.forEach((ebp, i) => {
      if (!validate({ data: ebp.org_name, dataRequired: "rusText" }))
        arr.push(`пункт 18(${i + 1}): Наименование юридического лица`);
      if (!validate({ data: ebp.organization_form, dataRequired: "rusText" }))
        arr.push(`пункт 18(${i + 1}): Организационно-правовая форма`);
    });
  // полей пункта 31 «Дата выдачи» не позднее даты внесения сведений, не ранее числа, месяца и года рождения,
  invalid = invalidMinMaxDate(
    obj.pass_issue_date,
    obj.birth_date,
    formatDate(new Date(), fmtDate),
    "пункт 31 «Дата выдачи» должна быть не позднее даты внесения сведений и не ранее числа, месяца и года рождения"
  );
  if (invalid && obj.pass_issue_date) arr.push(invalid);
  // полей пункта 32 «Дата выдачи» не позднее даты внесения сведений, не ранее числа, месяца и года рождения;
  invalid = invalidMinMaxDate(
    obj.foreign_pass_issue_date,
    obj.birth_date,
    formatDate(new Date(), fmtDate),
    "пункт 32 «Дата выдачи» должна быть не позднее даты внесения сведений и не ранее числа, месяца и года рождения"
  );
  if (invalid && obj.foreign_pass_issue_date) arr.push(invalid);
  // запрет на использование латинских букв и символов при заполнении полей
  // пункт 34: фамилия, имя, отчество пользователя, место работы, должность по месту работы
  if (Array.isArray(obj.relatives))
    obj.relatives.forEach((re, i) => {
      if (!validate({ data: re.last_name, dataRequired: "rusText" }))
        arr.push(`пункт 34(${i + 1}): Фамилия`);
      if (!validate({ data: re.first_name, dataRequired: "rusText" }))
        arr.push(`пункт 34(${i + 1}): Имя`);
      if (!validate({ data: re.middle_name, dataRequired: "rusText" }))
        arr.push(`пункт 34(${i + 1}): Отчество`);
      if (!validate({ data: re.org_name, dataRequired: "rusText" }))
        arr.push(`пункт 34(${i + 1}): Место работы`);
      if (!validate({ data: re.position, dataRequired: "rusText" }))
        arr.push(`пункт 34(${i + 1}): Должность по месту работы`);
      if (!validate({ data: re.organization_form, dataRequired: "rusText" }))
        arr.push(`пункт 34(${i + 1}): Организационно-правовая форма`);
    });
  return arr.length > 0 ? arr : false;
}
