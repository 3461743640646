import DeclarationListFull from "./DeclarationListFull.vue";
import COI from "./COI/COI.vue";
import ReportHeader from "./Reports/ReportHeader.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "DeclarationsList",
  props: {
    declarationsObj: {
      type: Array,
      default: () => []
    }
  },
  created() {
    this.fetchCoi();
    this.updateCoiDirectory();
  },
  components: {
    DeclarationListFull,
    ReportHeader,
    COI
  },
  data: () => ({
    tabIndex: 0
  }),
  computed: {
    ...mapGetters("coi", ["coiCount"]),
    ...mapGetters("declarations", ["declarationsCount"])
  },
  methods: {
    ...mapActions("coi", ["fetchCoi", "updateCoiDirectory"]),
    clickTabs() {
      this.$emit("clickTabs", this.tabIndex);
    }
  }
};