// перечень прав для ролей пользователей
const permKeys = [
  // декларации
  "view_wa_coid", // Посмотреть ЭДКИ в рабочей области
  "assign_wa_coid", // Назначить ответственного за рассмотрение ЭДКИ в рабочей области
  "commission_wa_coid", // Направить ЭДКИ на комиссию в рабочей области
  "decision_wa_coid", // Предоставить решение комиссии по ЭДКИ в рабочей области
  "complete_wa_coid", // Завершить рассмотрение ЭДКИ в рабочей области
  "view_my_coid", // Посмотреть ЭДКИ в личном кабинете
  "change_my_coid", // Отредактировать ЭДКИ в личном кабинете
  "add_my_coid", // Создать ЭДКИ в личном кабинете
  "copy_my_coid", // Создать копию ЭДКИ в личном кабине
  "check_wa_coid", // Завершить задачу по рассмотрению ЭДКИ в рабочей области
  "submit_my_coid", // Отправить ЭДКИ на рассмотрение в личном кабинете
  // КП
  "add_wa_cp", // Создать КП в рабочей области
  "assign_wa_cp", // Назначить КП в рабочей области
  "check_wa_cp", // Проверить КП в рабочей области
  "complete_wa_cp", // Завершить КП в рабочей области
  "prolong_wa_cp", // Продлить КП в рабочей области
  "view_wa_cp", //  У кого его нет, тем вообще не надо показывать вкладку и запрашивать что-либо о КП задача 396
  // КИ
  "add_wa_coi", // Создать КИ в РО
  "confirm_wa_coi", // Подтвердить КИ в РО
  "assign_wa_coi", // Назначить ответственного КИ в РО
  "dismiss_wa_coi", // Отметить КИ как отсутствующий (в РО)
  "settle_wa_coi", // Отметить КИ как урегулированный (в РО)

  // библиотека знаний
  "view_admin_lnatype", // Посмотреть справочник типов ЛНА в админке
  "change_admin_lnatype", // Отредактировать справочник типов ЛНА в админке
  "add_admin_lnatype", // Добавить тип ЛНА в справочнике в админке
  "add_wa_libsection", // Добавить раздел БЗ в рабочей области
  "change_wa_libsection", // Изменить раздел БЗ в рабочей области
  "add_wa_libmaterial", // Добавить материал БЗ в рабочей области
  "change_wa_libmaterial", // Изменить материал БЗ в рабочей области
  "view_wa_libmaterial", // Просмотреть материал БЗ в рабочей области
  "assign_wa_libmaterial", // Назначить ознакомление в рабочей области
  "confirm_wa_libmaterial", // Подтвердить назначенное ознакомление в рабочей области
  "publish_wa_libmaterial", // Опубликовать материал в рабочей области
  "close_wa_libmaterial", // Снять с публикации материал в рабочей области
  "archive_wa_libmaterial", // Отправить в архив материал в рабочей области

  // обучение
  "view_wa_course", // Посмотреть курс в рабочей области
  "add_wa_course", // Создать курс в рабочей области
  "change_wa_course", // Отредактировать курс в рабочей области
  "assign_wa_course", // Назначить курс в рабочей области
  "publish_wa_course", // Опубликовать курс из рабочей области
  "close_wa_course", // Скрыть курс из рабочей области
  "start_my_course", // Начать курс в личном кабинете
  "finish_my_course", // Завершить курс в личном кабинете
  "unassign_wa_course", // Отменить назначение прохождения курса из рабочей области
  "confirm_wa_course_assignment", // Утвердить назначение прохождения курса из рабочей области
  "change_wa_course_assignment", // Изменить условия прохождения курса из рабочей области
  "archive_wa_course", //Архивировать курс из рабочей области

  // внутренние проверки
  "view_wa_innercheck", // Посмотреть ВП в рабочей области
  "change_wa_innercheck", // Отредактировать ВП в рабочей области
  "add_wa_innercheck", // Создать ВП в рабочей области
  "comment_wa_innercheck", // Комментировать ВП в рабочей области
  "assign_wa_innercheck", // Назначить проведение ВП в рабочей области
  "perform_wa_innercheck", // Провести назначенную ВП в рабочей области
  "complete_wa_innercheck", // Завершить ВП в рабочей области
  "view_admin_innercheck_categories", // Просмотреть справочник категорий проверок в админке
  "delete_wa_innercheck", // право удалять ВП в рабочей области
  "download_report_wa_innercheck", // скачать отчет о ВП в рабочей области

  // деловые партнеры
  "view_wa_bp", // Посмотреть ДП в рабочей области
  "change_wa_bp", // Отредактировать ДП в рабочей области
  "add_wa_bp", // Создать ДП в рабочей области
  "delete_wa_bp", // Удалить ДП в рабочей области
  "change_wa_bp_final_risk", // Отредактировать итоговый уровень риска ДП в рабочей области
  "view_admin_bp", // право на просмотр ДП в админке (справочник)

  // горячая линия
  "add_wa_hotmsg", // Создать сообщение в рабочей области
  "change_wa_hotmsg", // Отредактировать сообщение в рабочей области
  "view_wa_hotmsg", // Посмотреть сообщение в рабочей области
  "delete_wa_hotmsg", // Удалить сообщение в рабочей области
  "assign_wa_dept_chief", // Назначить руководителя подразделения исполнителя в рабочей области
  "assign_wa_responsible", // Назначить ответственного исполнителя
  "view_admin_hotmsgcat", // Посмотреть справочник типов сообщений в админке
  "change_admin_hotmsgcat", // Отредактировать справочник типов сообщений в админке
  "add_admin_hotmsgcat", // Добавить тип сообщения в справочнике в админке
  "complete_wa_hotmsg", // Завершить рассмотрение сообщения в рабочей области
  "prolong_wa_hotmsg", // Продлить рассмотрение сообщения в рабочей области
  "redirect_wa_hotmsg", // Назначить курирующего ЗГД сообщению в рабочей области

  // администрирование
  "access_admin", // карторчка администрирование на панели инструментов
  "assign_org_user", // назначить организацию для пользователя
  "view_admin_organization", // Посмотреть организацию
  "view_admin_user", // Посмотреть пользователя
  "delete_admin_user", // удалить пользователя
  "view_wa_worker", // Посмотреть работника в рабочей области
  "change_wa_worker", // Отредактировать работника в рабочей области
  "view_admin_department", // Посмотреть структурное подразделение
  "add_admin_department", // Создать структурное подразделение через админпанель
  "change_admin_department", // Отредактировать структурное подразделение через админпанель
  "delete_admin_department", // Удалить структурное подразделение через админпанель
  "change_org_user", // Изменить организацию пользователя
  "view_admin_cp_types", // Просмотреть справочник типов КП в админке
  "add_admin_cp_types", // Добавить запись в справочник типов КП в админке
  "change_admin_cp_types", // Отредактировать запись в справочнике типов КП в админке
  "set_site_properties", // Просматривать, создавать, редактировать глобальные настройки Платформы
  "view_admin_role_names", // Просмотреть справочник ролей в админке
  "change_admin_role_names", // Редактировать названия ролей в админке

  "add_admin_country", // Добавить страну в справочник в админке
  "change_admin_country", // Отредактировать справочник стран в админке
  "delete_admin_country", // Удалить страну в справочнике в админке
  "view_admin_deptransstats", // Посмотреть статистику для Дептранса в админке
  "access_admin_orgs_stats", // новое право посмотреть статистику для Дептранса в админке
  "view_security_events", // Просматривать события безопасности
  "list_orgs", // имеют право просматривать организации,
  "change_user_password", // Изменить пароль пользователя в админке
  "change_admin_password", //Изменить пароль администратора в админке
  "change_risk_calc_settings", // настройка оценки рисков в админке
  "view_admin_notif_settings", // Просмотреть настройки уведомлений в админке
  "change_admin_notif_settings", // Изменить настройки уведомлений в админке

  // profile
  "access_working_area", // карточка личные листки на панели инструментов
  "access_account", // Есть доступ к личному кабинету - header
  "view_my_profile", // Посмотреть ЛЛ в личном кабинете
  "change_my_profile", // Отредактировать ЛЛ в личном кабинете
  "copy_my_profile", // Создать копию ЛЛ в личном кабинете
  "process_my_profile", // Управлять процессом ЛЛ в личном кабинете
  "view_my_libmaterial", // просмотр ознакомлений в личном кабинете

  "view_wa_profile", //Просмотреть ЛЛ в рабочей области
  "validate_wa_profile", //Отправить на проверку ЛЛ в рабочей области
  "approve_wa_profile", //Одобрить ЛЛ в рабочей области
  "decline_wa_profile", //Отклонить ЛЛ в рабочей области
  "change_profile_required_fields", // настроить обязательность полей ЛЛ в админке

  // profile checks
  "view_wa_profilecheck", //Просмотреть ЛЛ в рабочей области
  "complete_wa_profilecheck", // Управлять процессом ЛЛ в рабочей области

  // риски
  "add_wa_risk", // Создать риск в рабочей области
  "change_wa_risk", // Отредактировать риск в рабочей области
  "view_wa_risk", // Посмотреть риск в рабочей области
  "delete_wa_risk", // Удалить риск в рабочей области
  "add_wa_matrix", // Создать матрицу в рабочей области
  "change_wa_matrix", // Отредактировать матрицу в рабочей области
  "view_wa_matrix", // Посмотреть матрицу в рабочей области
  "approve_wa_matrix", // Одобрить и назначить дальнейшее согласование матрицы в рабочей области
  "resolve_wa_matrix", // Утвердить или отклонить матрицу в рабочей области
  "assign_wa_matrix_checks", // Назначить ответственного за согласование матрицы в рабочей области
  "view_wa_matrix_checks", // Просматривать назначения матрицы на согласование в рабочей области
  "change_wa_matrix_checks", // Редактировать назначения матрицы на согласование в рабочей области
  "delete_wa_matrix_checks", // Удалять назначения матрицы на согласование в рабочей области

  // автосверка
  "view_wa_autocheck", // Просмотреть результаты авторсверки в рабочей области
  "trigger_wa_autocheck", // Запустить автосверку в рабочей области
  "view_autocheck_notification_message", // для получения сообщения о новых автосверках
];

const perm = permKeys.reduce((acc, key) => {
  acc[key] = key;
  return acc;
}, {});

export default perm;
