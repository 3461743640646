import { fetchAPI } from "@/helpers/fetch";
import mutations from "@/store/mutations";
import { formatObjDate } from "@/helpers/date";
import { wrapCommitDispatch } from "@/helpers/global";

function additionCp(obj) {
  obj.datePlanned = formatObjDate(obj.date_planned);
  return obj;
}

const {
  CP,
  CP_COUNT,
  CP_LOADING,
  CP_DIRECTORY,
  CP_DIRECTORY_LOADING,
  // attach
  CP_ATTACHMENTS,
  CP_ATTACHMENTS_COUNT,
  CP_ATTACHMENTS_LOADING,
  // form
  CP_FORM,
  CP_FORM_LOADING,
  CP_FORM_CHANGE_LOADING,
  // comments
  CP_COMMENTS,
  CP_COMMENTS_COUNT,
  CP_COMMENTS_LOADING,
  // events
  CP_EVENTS,
  CP_EVENTS_COUNT,
  CP_EVENTS_LOADING,
} = mutations;
const formInit = {
  target_users: [{}],
  foundations_coi: [],
  foundations_message: [],
  foundations_innercheck: [],
  foundations_coid: [],
};

const cpStore = {
  namespaced: true,
  state: {
    cp: [],
    cpCount: 0,
    cpLoading: false,
    cpDirectory: [], // список типов КП
    cpDirectoryLoading: false,
    // attach
    cpAttach: [],
    cpAttachCount: 0,
    cpAttachLoading: false,
    // form
    cpForm: JSON.parse(JSON.stringify(formInit)),
    cpFormLoading: false,
    cpFormChangeLoading: false,
    // comments
    cpComments: [],
    cpCommentsCount: 0,
    cpCommentsLoading: false,
    // events
    cpEvents: [],
    cpEventsCount: 0,
    cpEventsLoading: false,
  },
  getters: {
    cp: ({ cp }) => cp,
    cpCount: ({ cpCount }) => cpCount,
    cpLoading: ({ cpLoading }) => cpLoading,
    cpDirectory: ({ cpDirectory }) => cpDirectory,
    cpDirectoryLoading: ({ cpDirectoryLoading }) => cpDirectoryLoading,
    // attach
    cpAttach: ({ cpAttach }) => cpAttach,
    cpAttachCount: ({ cpAttachCount }) => cpAttachCount,
    cpAttachLoading: ({ cpAttachLoading }) => cpAttachLoading,
    // form
    cpForm: ({ cpForm }) => cpForm,
    cpFormLoading: ({ cpFormLoading }) => cpFormLoading,
    cpFormChangeLoading: ({ cpFormChangeLoading }) => cpFormChangeLoading,
    // comments
    cpComments: ({ cpComments }) => cpComments,
    cpCommentsCount: ({ cpCommentsCount }) => cpCommentsCount,
    cpCommentsLoading: ({ cpCommentsLoading }) => cpCommentsLoading,
    // events
    cpEvents: ({ cpEvents }) => cpEvents,
    cpEventsCount: ({ cpEventsCount }) => cpEventsCount,
    cpEventsLoading: ({ cpEventsLoading }) => cpEventsLoading,
  },
  mutations: {
    [CP](state, value) {
      state.cp = value;
    },
    [CP_COUNT](state, value) {
      state.cpCount = Number(value);
    },
    [CP_LOADING](state, value) {
      state.cpLoading = value;
    },
    [CP_DIRECTORY](state, value) {
      state.cpDirectory = value;
    },
    [CP_DIRECTORY_LOADING](state, value) {
      state.cpDirectoryLoading = value;
    },
    // attach
    [CP_ATTACHMENTS](state, value) {
      state.cpAttach = value;
    },
    [CP_ATTACHMENTS_COUNT](state, value) {
      state.cpAttachCount = Number(value);
    },
    [CP_ATTACHMENTS_LOADING](state, value) {
      state.cpAttachLoading = value;
    },
    // form
    [CP_FORM](state, value) {
      state.cpForm = value;
    },
    [CP_FORM_LOADING](state, value) {
      state.cpFormLoading = value;
    },
    [CP_FORM_CHANGE_LOADING](state, value) {
      state.cpFormChangeLoading = value;
    },
    // comments
    [CP_COMMENTS](state, value) {
      state.cpComments = value;
    },
    [CP_COMMENTS_COUNT](state, value) {
      state.cpCommentsCount = Number(value);
    },
    [CP_COMMENTS_LOADING](state, value) {
      state.cpCommentsLoading = value;
    },
    // events
    [CP_EVENTS](state, value) {
      state.cpEvents = value;
    },
    [CP_EVENTS_COUNT](state, value) {
      state.cpEventsCount = Number(value);
    },
    [CP_EVENTS_LOADING](state, value) {
      state.cpEventsLoading = value;
    },
  },
  actions: {
    // запрос КП (список)
    async fetchCp(
      context,
      { sortBy, sortDesc, page, filters, pageSize, searchString } = {
        sortBy: null,
        sortDesc: true,
        page: 1,
        filters: {},
        pageSize: 20,
        searchString: "",
      }
    ) {
      await wrapCommitDispatch(
        context,
        async () => {
          const sortParam = sortBy ? (sortDesc ? "-" + sortBy : sortBy) : null;
          const requestParams = { page: page, page_size: pageSize };
          if (searchString) {
            requestParams.search = searchString;
          }
          if (sortParam) requestParams.sort = sortParam;
          // добавим фильтры в параметры
          Object.keys(filters).forEach((key) => {
            if (filters[key] !== null) requestParams[key] = filters[key]; // что-то есть в фильтре
          });
          const response = await fetchAPI(
            "/control-procedures/",
            requestParams
          );
          context.commit(CP, response.results);
          context.commit(CP_COUNT, response.count);
        },
        CP_LOADING
      );
    },
    // cpForm update
    async updateCPForm(context, id) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(`/control-procedures/${id}/`);
          context.commit(CP_FORM, additionCp(response));
        },
        CP_FORM_LOADING
      );
    },
    // cpForm change
    async changeCPForm(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/control-procedures/${data.id}/`,
            null,
            "PATCH",
            data.params
          );
          context.commit(CP_FORM, additionCp(response));
          return "Изменения для КП внесены";
        },
        CP_FORM_CHANGE_LOADING
      );
    },
    // cpForm create
    async createCPForm(context, body = {}) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/control-procedures/`,
            null,
            "POST",
            body
          );
          context.commit(CP_FORM, additionCp(response));
          return "Создана новая КП";
        },
        CP_LOADING
      );
    },
    // cpForm create from inner check
    async createCPFormFromInnerCheck(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          context.commit(CP_FORM, additionCp({})); // обнулим
          const response = await fetchAPI(
            `/innerchecks/${data.id}/ctrlprocedure/`,
            null,
            "POST"
          );
          context.commit(CP_FORM, additionCp(response));
          return "Создана новая КП";
        },
        CP_LOADING
      );
    },
    // запрос списка КП для select
    async updateCpDirectory(context) {
      await wrapCommitDispatch(
        context,
        async () => {
          const cpDirectory = await fetchAPI("/cp-types/reference/");
          context.commit(CP_DIRECTORY, cpDirectory);
        },
        CP_DIRECTORY_LOADING
      );
    },
    // запрос вложений КП
    async updateCpAttach(context, { id, page }) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/control-procedures/${id}/attachments/`,
            { page }
          );
          context.commit(CP_ATTACHMENTS, response.results);
          context.commit(CP_ATTACHMENTS_COUNT, response.count);
        },
        CP_ATTACHMENTS_LOADING
      );
    },
    // создать вложение к КП
    async createCpAttach(context, { id, formData }) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/control-procedures/${id}/attachments/`,
            null,
            "POST",
            formData,
            true
          );
          return "Файл прикреплен к КП";
        },
        CP_ATTACHMENTS_LOADING
      );
    },
    // удалить вложение к КП
    async deleteCpAttach(context, { id, idAttach }) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/control-procedures/${id}/attachments/${idAttach}/`,
            null,
            "DELETE"
          );
          return "Файл удален";
        },
        CP_ATTACHMENTS_LOADING
      );
    },
    // запрос комментариев к КП
    async updateCpComments(context, { id, page }) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/control-procedures/${id}/comments/`,
            { page }
          );
          context.commit(CP_COMMENTS, response.results);
          context.commit(CP_COMMENTS_COUNT, response.count);
        },
        CP_COMMENTS_LOADING
      );
    },
    // создать комментарий к КП
    async createCpComments(context, { id, body }) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(`/control-procedures/${id}/comments/`, null, "POST", {
            body,
          });
          return "Комментарий к КП создан";
        },
        CP_COMMENTS_LOADING
      );
    },
    // изменить комментарий к КП
    async changeCpComments(context, { id, idComment, body }) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/control-procedures/${id}/comments/${idComment}/`,
            null,
            "PATCH",
            {
              body,
            }
          );
          return "Комментарий к КП изменен";
        },
        CP_COMMENTS_LOADING
      );
    },
    // удалить комментарий к КП
    async deleteCpComments(context, { id, idComment }) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/control-procedures/${id}/comments/${idComment}/`,
            null,
            "DELETE"
          );
          return "Комментарий к КП удален";
        },
        CP_COMMENTS_LOADING
      );
    },
    // изменение статуса КП
    async changeStateCp(context, { id, state, msg }) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/control-procedures/${id}/transition/`,
            null,
            "PATCH",
            { state }
          );
          context.commit(CP_FORM, additionCp(response));
          return msg;
        },
        CP_FORM_CHANGE_LOADING
      );
    },
    // events update
    async updateCpEvents(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/cp-events/", data);
          context.commit(CP_EVENTS, response.results);
          context.commit(CP_EVENTS_COUNT, response.count);
        },
        CP_EVENTS_LOADING
      );
    },
    // сброс формы КП
    async dropCPForm(context) {
      await wrapCommitDispatch(context, async () => {
        context.commit(
          CP_FORM,
          additionCp(JSON.parse(JSON.stringify(formInit)))
        );
      });
    },
  },
};

export default cpStore;
