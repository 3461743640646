import { mapGetters, mapActions } from "vuex";
import { formatDate, fmtDateTime } from "@/helpers/date";
import { mapStateToText } from "@/helpers/profile";
import links from "@/router/links";
import { limitBound } from "@/helpers/text";
export default {
  name: "ProfileCardList",
  data: () => ({
    showModalCopy: false,
    currentId: null,
    perPage: 10,
    gotoPage: 1,
    currentPage: 1,
    links,
    columns: [{
      key: "index",
      label: "№ п/п"
    }, {
      key: "id",
      label: "UID"
    }, {
      key: "created_at",
      label: "Дата создания",
      formatter: value => formatDate(value, fmtDateTime)
    }, {
      key: "submitted_at",
      label: "Дата регистрации",
      formatter: value => formatDate(value, fmtDateTime)
    }, {
      key: "state",
      label: "Статус",
      formatter: value => {
        return mapStateToText[value];
      }
    }, {
      key: "changeProfile",
      label: "",
      class: "text-center"
    }, {
      key: "copy",
      label: "",
      class: "text-center"
    }]
  }),
  methods: {
    ...mapActions("profiles", ["fetchProfiles", "cloneProfile"]),
    copy(id) {
      this.currentId = id;
      this.showModalCopy = true;
    },
    async doCopy() {
      await this.cloneProfile(this.currentId);
      await this.fetchProfiles(this.currentPage);
    },
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.profilesCount / this.perPage, this.currentPage);
      this.fetchProfiles(this.currentPage);
    }
  },
  computed: {
    ...mapGetters("profiles", ["profilesList", "profilesCount", "profilesKadrLoading"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.fetchProfiles(value);
      }
    }
  }
};